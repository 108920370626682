import React from "react";
import { Content, Row, Col, DataTable } from "adminlte-2-react";
//import axios from "axios";
import API from "../../constants";
import ApiRequest from "../../ApiRequest";
import LoadingSpinner from "../../components/LoadingSpinner";
import LoadingAjax from "../../components/Loading";
import { confirmAlert } from "react-confirm-alert";

const loyaltyDistributionList_url = API.apiEndpoint + API.loyaltyCardsDistribution;
const userview_url = API.apiEndpoint + API.adminUserView;

export default class StationLoyaltyDistributionListing extends React.Component {
  
  state = {
    distributionList: [],
    loading: true,
    error: false,
    message: "",
  };

  getData = async () => {
    let options = { url: loyaltyDistributionList_url };

    let api_response = await ApiRequest.get(options);
    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      const results_with_fetched_users = [];

      for (const result of api_response.data.results) {
        let user_url = userview_url + "/" + result.added_by;
        let options = { url: user_url };
        let user_api_response = await ApiRequest.get(options);
        if (user_api_response.error) {
          results_with_fetched_users.push(result);
        } else {
          results_with_fetched_users.push({
            ...result,
            added_by: {
              id: result.added_by,
              user_name: user_api_response.data.name,
            },
          });
        }
      }
      this.setState({
        distributionList: results_with_fetched_users,
        loading: false,
        error: false,
        message: api_response.message,
      });
    }
  }

  async componentDidMount() {
    this.getData();
  }

  rescue_value(value) {
    return value === undefined ? "" : value;
  }

  

  deleteStationCardsDistribution = (stationCardsDistributionId) => {
    console.log("Hello from deleteStationCardsDistribution")
    confirmAlert({
      title: "Station Cards Distribution",
      message: "Are you sure you want to delete this Station Cards Distribution?",
      buttons: [
        {
          label: "Yes",
          // onClick: () => this.confirmDeleteCardRepository(val),
          onClick: async () => {
            let options = {
              url: loyaltyDistributionList_url + "/" + stationCardsDistributionId,
            };
            this.setState({ loading: true });
            let api_response = await ApiRequest.delete(options);
            if (api_response.error) {
              this.setState({
                loading: false,
                error: true,
                message: api_response.message,
              });
            } else {
              this.setState({
                loading: false,
                success: true,
                success_message: api_response.message,
              });
              await this.getData();
            }
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      keyCodeForClose: [8, 32],
    });
  };

  render() {
    const columns = [
      {
        title: "ID",
        data: "id",
        render: (value) => this.rescue_value(value),
      },
      {
        title: "Store Name",
        data: "store_name",
        render: (value) => this.rescue_value(value),
      },
      {
        title: "Store Code",
        data: "store_code",
        render: (value) => this.rescue_value(value),
      },
      {
        title: "Distributed Cards",
        data: "distributed_cards_count",
        render: (value) => this.rescue_value(value),
      },
      {
        title: "Activated",
        data: "total_distributed_cards_activated",
        render: (value) => this.rescue_value(value),
      },
      {
        title: "Non Activated",
        data: "total_distributed_cards_non_activated",
        render: (value) => this.rescue_value(value),
      },
      {
        title: "Filler Name",
        data: "filler_name",
        render: (value) => this.rescue_value(value),
      },
      {
        title: "Filler Phone Number",
        data: "filler_phone_number",
        render: (value) => this.rescue_value(value),
      },
      {
        title: "Created At",
        data: "createdAt",
        render: (value) => ApiRequest.date_time_format_utc(value),
      }
    ];

    if (ApiRequest.can_access("station", "can_view_single")) {
      columns.push({
        title: "Actions",
        data: "id",
        sortable: false,
        render: (id) => (
          <div>
            <a class="btn btn-primary btn-xs mr-5" href={"/stations_loyalty_distributions_form/edit/" + id}>
              {" "}
              <i class="fa fa-edit" />
              {"    "}
            </a>

            <button class="btn btn-primary btn-xs on-click-delete">
              <i class="fa fa-trash" />
            </button>
          </div>
        ),
      });
      columns.push({
        title: "Added By",
        data: "added_by",
        render: (data) => {
          if(data && ApiRequest.can_access("admin-user", "can_view_single")) {
            return (
              <a class="btn btn-primary btn-xs mr-5" href={"/admin_user_view/" + data?.id}>
                {data?.user_name}
              </a>
            )
          } else {
            return null;
          }
        },
      });
    }

    const { loading, error, message } = this.state;
    return (
      <Content title="Stations Loyalty Cards Distribution" browserTitle="Stations Loyalty Cards Distribution">
        <LoadingAjax />
        <Row>
          {error && (
            <div
              class="alert alert-danger alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {message}
            </div>
          )}
          <Col xs={12}>
            <div className="box box-primary">
              <div className="box-header with-border box-table-cus">
                <h3 className="box-title">Stations Loyalty Cards Distribution</h3>
                <div className="box-tools">
                  <div>
                    <a
                      class="btn btn-sm btn-primary mr-5"
                      href={"/stations_loyalty_distributions_form/"}
                    >
                      {" "}
                      Add New
                      {"    "}
                    </a>
                  </div>
                </div>
              </div>
              <div className="box-body">
                {loading ? (
                  <LoadingSpinner />
                ) : (
                  <DataTable
                    title="Station Locator"
                    selectableRows={false}
                    columns={columns}
                    highlightOnHover={false}
                    pointerOnHover={false}
                    pagination={false}
                    striped={true}
                    data={this.state.distributionList}
                    paginationPerPage={3}
                    onClickEvents={{
                      "on-click-delete": (data, rowIdx, rowData) => {
                        this.deleteStationCardsDistribution(data);
                      },
                    }}
                    id="externally-controlled"
                    options={{}}
                  />
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Content>
    );
  }
}
