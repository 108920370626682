import React from "react";
import { Content } from "adminlte-2-react";
//import axios from "axios";
import API from "../../constants";
import ApiRequest from "../../ApiRequest";
import LoadingAjax from "../../components/Loading";
//import LoadingSpinner from "../../components/LoadingSpinner";
import DatePicker from "react-datepicker";
import $ from "jquery";
import Select from "react-select";
import moment from "moment";

const edit_topup_reward_form_url = API.apiEndpoint + API.TopupRewardPromotions;
const tierTargetList_url = API.apiEndpoint + API.tierTarget;

export default class TopupPromotionEditForm extends React.Component {
  state = {
    topup_promotion_list: [],
    loyaltyGameId: "",
    error: false,
    message: "",
    loading: true,
    success: false,
    success_message: "",
    active: "",
    start: "",
    end: "",
    topupPromotions: [],
  };

  async componentDidMount() {
    let url = edit_topup_reward_form_url + "/" + this.props.match.params.id;
    let options = { url: url };
    let api_response = await ApiRequest.get(options);
    let data = api_response.data;
    let tierTargeOptions = { url: tierTargetList_url };
    let tier_response = await ApiRequest.get(tierTargeOptions);

    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      let topupPromotions = [];
      tier_response.data.map((store) => {
        topupPromotions.push({
          value: store.CardTypeName,
          label: store.CardTypeName,
        });
      });

      let tierValue = [];
      api_response.data.tiers.map((store) => {
        tierValue.push({
          value: store,
          label: store,
        });
      });

      let card_type_res = {};
      api_response.data.card_type.map((PVChecked) => {
        card_type_res[PVChecked] = true;
      });

      var startDate = new Date(data.from);
      var endDate = new Date(moment.utc(data.to).format("MM/DD/YYYY"));

      this.setState({
        topupPromotions: topupPromotions,
        topup_promotion_date_from: startDate,
        topup_promotion_date_to: endDate,
        tierSelected: tierValue,
        topupValue: data.transaction_value,
        tierName: data.name,
        card_type: card_type_res,
        loading: false,
        error: false,
        message: api_response.message,
        isPChecked: card_type_res.physical == true ? true : false,
        isVChecked: card_type_res.virtual == true ? true : false,
      });
    }
  }
  promotionTypeChange = (event) => {
    this.setState({ promotionType: event.target.value });
  };
  tierTargetChange = (event) => {
    this.setState({ tier_target: event.target.value });
  };
  topupValueChange = (event) => {
    this.setState({ topupValue: event.target.value });
  };
  topupPromotionStart = async (date) => {
    this.setState({
      topup_promotion_date_from: date,
      enableBtn: true,
    });
  };
  topupPromotionEnd = async (date) => {
    this.setState({
      topup_promotion_date_to: date,
      enableBtn: true,
    });
  };
  handleTierChange = (selectedTierOption) => {
    this.setState({ tierSelected: selectedTierOption });
  };
  tierNameChange = (event) => {
    this.setState({ tierName: event.target.value });
  };
  onChangePhysicalCheck = (event) => {
    const { physical } = this.state;
    this.setState({
      physical: physical,
      enableBtn: true,
      isPChecked: !this.state.isPChecked,
    });
  };
  onChangeVirtualCheck = (event) => {
    const { virtual } = this.state;
    this.setState({
      virtual: virtual,
      enableBtn: true,
      isVChecked: !this.state.isVChecked,
    });
  };

  saveTopupPromotions = async (event) => {
    let error = false;
    // if (!this.state.promotionType) {
    //   error = true;
    //   this.setState({
    //     error: true,
    //     message: "Promotion Type is required",
    //   });
    //   return;
    // }

    if (!this.state.topup_promotion_date_from) {
      error = true;
      this.setState({
        error: true,
        message: "Start Date is required",
      });
      return;
    }
    if (!this.state.topup_promotion_date_to) {
      error = true;
      this.setState({
        error: true,
        message: "End Date is required",
      });
      return;
    }
    if (!this.state.tierName) {
      error = true;
      this.setState({
        error: true,
        message: "Name value is required",
      });
      return;
    }
    if (!this.state.tierSelected) {
      error = true;
      this.setState({
        error: true,
        message: "Tier is required",
      });
      return;
    }
    if (!this.state.topupValue) {
      error = true;
      this.setState({
        error: true,
        message: "Topup value is required",
      });
      return;
    }

    var tierPost = [];
    this.state.tierSelected.map((store) => {
      tierPost.push(store.value);
    });

    //var moment = require("moment");

    const formData = new FormData();
    formData.append(
      "from",
      moment.utc(this.state.topup_promotion_date_from).startOf("day").valueOf()
    );
    //formData.append("to", this.state.cash_back_promotion_date_to);
    formData.append(
      "to",
      moment.utc(this.state.topup_promotion_date_to).endOf("day").valueOf()
    );
    formData.append("transaction_value", this.state.topupValue);
    formData.append("name", this.state.tierName);
    formData.append("tiers", JSON.stringify(tierPost));
    formData.append("type", "Online Topup");

    var card_data = [];
    if (this.state.isPChecked === true) {
      card_data.push("physical");
    }
    if (this.state.isVChecked === true) {
      card_data.push("virtual");
    }

    formData.append("card_type", JSON.stringify(card_data));

    let api_response = {};
    let options = { url: edit_topup_reward_form_url, form_data: formData };
    options.url = edit_topup_reward_form_url + "/" + this.props.match.params.id;
    api_response = await ApiRequest.put(options);

    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        success: true,
        loading: false,
        success_message: api_response.message,
      });
      setTimeout(
        function () {
          this.setState({ success: false });
          window.location = "/topup_promotion";
        }.bind(this),
        10000
      );
    }
  };
  backUser = () => {
    window.location = "/topup_promotion";
  };

  render() {
    let {
      error,
      message,
      success,
      success_message,
      topupPromotions,
      tierSelected,
    } = this.state;
    return (
      <Content title="Topup Promotion" browserTitle="Topup Promotion">
        <LoadingAjax />
        <div className="row">
          {error && (
            <div
              className="alert alert-danger alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {message}
            </div>
          )}
          {success && (
            <div
              className="alert alert-success alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {success_message}
            </div>
          )}
          <div className="col-md-12">
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">Topup Promotion Form </h3>
              </div>

              <div className="box-body">
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="promotion_type">Promotion Type</label>
                    <select
                      className="form-control"
                      id="promotion_type"
                      value={this.state.promotionType}
                      onChange={this.promotionTypeChange}
                    >
                      <option>Online Topup</option>
                    </select>
                  </div>
                </div>
                <div className="form-group rangeFilter col-sm-4">
                  <label>Promotion Date</label>
                  <DatePicker
                    dateFormat="MM/dd/yyyy"
                    selected={this.state.topup_promotion_date_from}
                    selectsStart
                    topup_promotion_date_from={
                      this.state.topup_promotion_date_from
                    }
                    topup_promotion_date_to={this.state.topup_promotion_date_to}
                    onChange={this.topupPromotionStart}
                    minDate={moment().toDate()}
                    placeholderText="From"
                  />
                  <DatePicker
                    dateFormat="MM/dd/yyyy"
                    selected={this.state.topup_promotion_date_to}
                    selectsEnd
                    topup_promotion_date_from={
                      this.state.topup_promotion_date_from
                    }
                    topup_promotion_date_to={this.state.topup_promotion_date_to}
                    onChange={this.topupPromotionEnd}
                    minDate={this.state.topup_promotion_date_from}
                    placeholderText="To"
                  />
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="tierName">Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Name"
                      value={this.state.tierName}
                      onChange={this.tierNameChange}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="tierTarget">Tier Target</label>
                    <Select
                      onChange={this.handleTierChange}
                      options={topupPromotions}
                      value={tierSelected}
                      isMulti
                      isSearchable
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="tierTarget">Topup value</label>
                    <div className="form-control form-group-custom ">
                      <input
                        type="number"
                        min="0"
                        className="bdr0 cusInput"
                        placeholder="000"
                        value={this.state.topupValue}
                        onChange={this.topupValueChange}
                      />
                      <span style={{ color: "#ccc" }}>OMR</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <label>
                    <input
                      className="ml-5"
                      onChange={this.onChangePhysicalCheck}
                      type="checkbox"
                      id="physicalId"
                      // checked={physical}
                      checked={this.state.isPChecked}
                    />
                    {"physical"}
                  </label>
                </div>
                <div className="col-md-4">
                  <label>
                    <input
                      className="ml-5"
                      onChange={this.onChangeVirtualCheck}
                      type="checkbox"
                      id="surveySponsored"
                      // checked={virtual}
                      checked={this.state.isVChecked}
                    />
                    {"virtual"}
                  </label>
                </div>
              </div>
              <div className="box-footer clearfix no-border">
                <button
                  onClick={this.saveTopupPromotions}
                  className="btn btn-primary pull-right "
                >
                  Submit
                </button>
                <button
                  onClick={this.backUser}
                  className="btn btn-default pull-right mr-10"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Content>
    );
  }
}
