import React, { Component } from "react";
import "./App.css";

import AdminLTE, { Sidebar, Navbar } from "adminlte-2-react";
import Dashboard from "./Dashboard";
import Listing from "./Listing";
import Login from "./modules/login";
import StationLocator from "./modules/station";
import StationReview from "./modules/station_review";
import SafetyTip from "./modules/safety_tips";
import ContactForm from "./modules/contact";
import Complaint from "./modules/complaint";
//import ComplaintType from "./modules/complaint_type";
//import News from "./modules/news";
import CMS from "./modules/cms";
import FuelPrices from "./modules/fuel";
import AdminUser from "./modules/admin_user";
import AppUser from "./modules/app_user";
import BasmaManagement from "./modules/basma_cards_management";
import WhiteList from "./modules/basma_white_list";
import Roles from "./modules/roles";
import Setting from "./modules/setting";
import Store from "./modules/store";
import AssignStore from "./modules/assign_store";
import Card from "./modules/card";
import CardTopUp from "./modules/card_top_up";
import CardReplace from "./modules/card_replace";
import ActiveCard from "./modules/active_card";
import OldCard from "./modules/old_card";
import AppView from "./modules/app_view";
import CardTransaction from "./modules/card_transaction";
import CardTransactionView from "./modules/card_transaction_view";
import RpsTransaction from "./modules/rps_transaction";
import RpsTransactionView from "./modules/rps_transaction_view";
import SfsTransaction from "./modules/sfs_transaction";
import SfsTransactionView from "./modules/sfs_transaction_view";
import Filter from "./modules/filter";
import AdminUserView from "./modules/admin_user_view";
import AdminUserForm from "./modules/admin_form";
import CardInventory from "./modules/card_inventory";
import CardSalesReport from "./modules/card_sales_report";
import CardTransactionsReports from "./modules/card_transactions_reports";
import UserReport from "./modules/users_report";
import RefuelingTransaction from "./modules/refueling_transaction";
import StoresTransactionsReport from "./modules/stores_transactions_report";
import AdminEditForm from "./modules/admin_form_edit";
import UserDelete from "./modules/user_delete";
//import { tsModuleBlock } from "@babel/types";
import ApiRequest from "./ApiRequest";
import Notification from "./modules/notification";
import UpdateVehicle from "./modules/update_vehicle";
import FuelForm from "./modules/fuel";
import RolesForm from "./modules/roles";
import EPaymentTransaction from "./modules/ePayment_transactions_report";
import ThawaniTransactions from "./modules/thawani_transactions_report";
import MakasibTransactions from "./modules/makasib_transactions_report";
import CardTopUpReverse from "./modules/card_top_up_reverse";
import BadgesStatistics from "./modules/badges_statistics";
import BadgesStatisticsView from "./modules/badges_statistics_view";
import OfflineLoyaltyRewards from "./modules/offline_loyalty_rewards";
import LimitHittingRecording from "./modules/limit_hit_record";

import QuestionBank from "./modules/question_bank";
import QuestionBankView from "./modules/question_bank_view";
import QuestionBankForm from "./modules/question_bank_form";
import QuestionEditForm from "./modules/question_bank_edit";

import Quiz from "./modules/quiz";
import QuizForm from "./modules/quiz_form";
import QuizFormEdit from "./modules/quiz_form_edit";
import QuizView from "./modules/quiz_form_view";

import Survey from "./modules/survey";
import SurveyForm from "./modules/survey_form";
import SurveyFormEdit from "./modules/survey_form_edit";
import SurveyView from "./modules/survey_view";

import ARGame from "./modules/ar_game";

import SpinWin from "./modules/spin_win";
import SpinWinView from "./modules/spin_win_view";
import SpinWinForm from "./modules/spin_win_form";
import SpinWinEditForm from "./modules/spin_win_edit";

import QuizReport from "./modules/quiz_report";
import SurveyReport from "./modules/survey_report";
import SpinWinReport from "./modules/spin_win_report";

import FuelVoucher from "./modules/fuel_voucher";
//import FuelVoucherView from "./modules/fuel_voucher_view";
import FuelVoucherForm from "./modules/fuel_voucher_form";
import FuelVoucherEditForm from "./modules/fuel_voucher_edit";

import TopupPromotion from "./modules/topup_promotion";
import TopupPromotionView from "./modules/topup_promotion_view";
import TopupPromotionForm from "./modules/topup_promotion_form";
import TopupPromotionEditForm from "./modules/topup_promotion_edit";

import PromoCode from "./modules/promo_code";
//import PromoCodeView from "./modules/promo_code_view";
import PromoCodeForm from "./modules/promo_code_form";
import PromoCodeEditForm from "./modules/promo_code_edit";

import CashBackPromotion from "./modules/cash_back_promotion";
import CashBackPromotionView from "./modules/cash_back_promotion_view";
import CashBackPromotionForm from "./modules/cash_back_promotion_form";
import CashBackPromotionEditForm from "./modules/cash_back_promotion_edit";

import CashBackPromotionsReport from "./modules/cash_back_promotions_report";
import OnlineTopupRewardsReport from "./modules/online_topup_rewards";
import PromoCodesReport from "./modules/promo_codes";

import VirtualBasmaReports from "./modules/virtual_basma_reports";

import InAppPromotions from "./modules/in_app_promotions";
// import SpinWinView from "./modules/spin_win_view";
import InAppPromotionsForm from "./modules/in_app_promotions_form";
import InAppPromotionsEditForm from "./modules/in_app_promotions_edit";

import BulkBlankCardActivationForm from "./modules/bulk_blank_card_activation_form";
import BulkGiftCardActivationList from "./modules/bulk_gift_card_activation_list";

import CardRepository from "./modules/card_repository";
import CardRepositoryForm from "./modules/card_repository_form";
import BulkGiftCardActivationForm from "./modules/bulk_gift_card_activation_form";

import SuperAdminListing from "./modules/super_admin";
import StationUserListing from "./modules/station_user";

import Campaign from "./modules/campaign";
import Campaign_form from "./modules/campaign_form";
import Campaign_view from "./modules/campaign_view";

import Product_bar_code from "./modules/product_bar_code";
import Product_bar_code_form from "./modules/product_bar_code_form";

import AddBadge from "./modules/add_badge";
import EditBadge from "./modules/badge_edit";

import Partner from "./modules/partner_listing";
import Partner_form from "./modules/partner_form";
import PartnerEditForm from "./modules/partner_form_edit";
import PartnerView from "./modules/partner_view";

// import PartnerReport from "./modules/partner_report";
// import PartnerReportView from "./modules/partner_report_view";

import MyGarage from "./modules/my-garage";
import MyGarageView from "./modules/my_garage_view";

import InsurancesReport from "./modules/insurances";
import StampCard from "./modules/stamp_card";
import StampCardAdd from "./modules/stamp_card_add";
import StampCardEdit from "./modules/stamp_card_edit";

import UploadVoucher from "./modules/upload_voucher";
import UploadVoucherAdd from "./modules/upload_voucher_add";
import UploadVoucherEdit from "./modules/upload_voucher_edit";

import NfcList from "./modules/nfc";
import NfcReport from "./modules/nfc_report";
import { DownloadModal } from "./components/DownloadModal";

import SelfServiceNozzles from "./modules/self_service_nozzles";
import SelfServiceNozzlesForm from "./modules/self_service_nozzles_form";

import OnboardingUrlGenerator from "./modules/onboarding_url_generator";

import StationLoyaltyDistribution from "./modules/station_loyalty_distribution";
import StationLoyaltyDistributionForm from "./modules/station_loyalty_distribution_form";
import StationLoyaltyDistributionReport from "./modules/station_loyalty_distribution_report";
import BusinessUserRequests from "./modules/business_user_requests";
import LimitHitView from "./modules/limit_hit_record/view";
import BusinessUserRequestsView from "./modules/business_user_requests/view";

const { Item } = Sidebar;
const { Entry } = Navbar;
//
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_login: localStorage.getItem("user_token"),
      permissions:
        typeof localStorage.getItem("permissions") === "string"
          ? JSON.parse(localStorage.getItem("permissions"))
          : localStorage.getItem("permissions"),
      role_name: localStorage.getItem("role_name"),
    };
  }
  handleClick = (e) => {
    localStorage.removeItem("user_token");
    localStorage.removeItem("role_name");
    localStorage.removeItem("permissions");
    window.location = "/login";
  };
  handleSidebar = (e) => {};
  sidebarMenu() {
    let sidebar_menu = [];

    if (ApiRequest.can_access("dashboard", "can_view")) {
      sidebar_menu.push(
        <Item
          active={true}
          key="dashboard"
          text="Dashboard"
          to="/"
          icon="fa-tachometer-alt"
        />
      );
    }
    if (ApiRequest.can_access("station", "can_view")) {
      sidebar_menu.push(
        <Item key="station" text="Stations" to="/stations" icon="fa-gas-pump" />
      );
    }
    // Loyalty group
    if (ApiRequest.can_access("basma-white-list", "can_view")) {
      let loyalty_children = [];
      if (ApiRequest.can_access("basma-white-list", "can_view")) {
        loyalty_children.push(
          <Item
            key="stations_loyalty_distributions"
            text="Stations Cards Distribution"
            to="/stations_loyalty_distributions"
            icon="fa-gas-pump"
          />
        );
      }
      if (ApiRequest.can_access("admin-user", "can_view")) {
        loyalty_children.push(
          <Item
            key="stations_loyalty_distributions_report"
            text="Stations Cards Dist. Report"
            to="/stations_loyalty_distributions_report"
            icon="fa-gas-pump"
          />
        );
      }
      if (ApiRequest.can_access("basma-white-list", "can_view")) {
        loyalty_children.push(
          <Item
            key="onboarding-url-generator"
            text="Onboarding Url Generator"
            to="/onboarding-url-generator"
            icon="fa-list-alt"
          />
        );
      }
      sidebar_menu.push(
        <Item
          key="loyalty_management"
          text="Loyalty Cards Management"
          icon="fa-user"
          children={loyalty_children}
        />
      );
    }

    if (ApiRequest.can_access("station-review", "can_view")) {
      sidebar_menu.push(
        <Item
          key="station_reviews"
          text="Station Reviews"
          to="/station_reviews"
          icon="fa-comments"
        />
      );
    }
    if (ApiRequest.can_access("station", "can_view")) {
      sidebar_menu.push(
        <Item
          key="self_service_nozzles"
          text="Self Service Nozzles"
          to="/self_service_nozzles"
          icon="fa-gas-pump"
        />
      );
    }
    if (ApiRequest.can_access("cms-pages", "can_view")) {
      sidebar_menu.push(
        <Item key="cms-pages" text="CMS Pages" to="/cms" icon="fa-file-alt" />
      );
    }
    if (ApiRequest.can_access("safety-tips", "can_view")) {
      sidebar_menu.push(
        <Item
          key="safety-tips"
          text="Safety Tips"
          to="/safety_tips"
          icon="fa-lightbulb"
        />
      );
    }
    if (ApiRequest.can_access("contact-form", "can_view")) {
      sidebar_menu.push(
        <Item key="contact" text="Contact" to="/contact" icon="fa-envelope" />
      );
    }
    if (ApiRequest.can_access("complaint-management", "can_view")) {
      sidebar_menu.push(
        <Item
          key="complaint-management"
          text="Complaint Management"
          to="/complaint"
          icon="fa-headset"
        />
      );
    }
    // if (ApiRequest.can_access("complaint_type")) {
    //   sidebar_menu
    //     .push
    //     <Item
    //       key="complaint_type"
    //       text="Complaint Type"
    //       to="/complaint_type"
    //       icon="fa-list-alt"
    //     />
    //     );
    // }

    if (ApiRequest.can_access("reports", "can_view") || ApiRequest.can_access("partner-user", "can_view")) {
      var reports_children = [];

      if (ApiRequest.can_access("reports", "can_view_cards_report")) {
        reports_children.push(
          <Item
            key="card_inventory"
            text="Card Inventory"
            to="/reports/card_inventory"
            icon="fa-circle"
          />
        );
      }
      if (ApiRequest.can_access("reports", "can_view_sales_report")) {
        reports_children.push(
          <Item
            key="card_sales_report"
            text="Card Sales Report"
            to="/reports/card_sales_report"
            icon="fa-circle"
          />
        );
      }
      if (
        ApiRequest.can_access("reports", "can_view_card_transactions_report")
      ) {
        reports_children.push(
          <Item
            key="card_transactions_reports"
            text="Card Transactions Reports"
            to="/reports/card_transactions_reports"
            icon="fa-circle"
          />
        );
      }
      if (ApiRequest.can_access("reports", "can_view_users_report")) {
        reports_children.push(
          <Item
            key="users_reports"
            text="Users Reports"
            to="/reports/users_reports"
            icon="fa-circle"
          />
        );
      }
      if (
        ApiRequest.can_access(
          "reports",
          "can_view_refueling_transaction_report"
        )
      ) {
        reports_children.push(
          <Item
            key="refueling_transaction"
            text="Refueling Transaction"
            to="/reports/refueling_transaction"
            icon="fa-circle"
          />
        );
      }

      if (
        ApiRequest.can_access("reports", "can_view_epayment_transaction_report")
      ) {
        reports_children.push(
          <Item
            key="ePayment-transactions-report"
            text="ePayment Transactions Report"
            to="/reports/ePayment_transactions_report"
            icon="fa-circle"
          />
        );
      }

      if (
        ApiRequest.can_access("reports", "can_view_epayment_transaction_report")
      ) {
        reports_children.push(
          <Item
            key="thawani-transactions-report"
            text="thawani Transactions Report"
            to="/reports/thawani_transactions_report"
            icon="fa-circle"
          />
        );
      }

      if (
        ApiRequest.can_access(
          "reports",
          "can_view_epayment_transaction_report"
        ) ||
        ApiRequest.can_access("partner-user", "can_view")
      ) {
        reports_children.push(
          <Item
            key="3p_topup-transactions-report"
            text="Partner topup requests Report"
            to="/reports/partner_topup_transactions_report"
            icon="fa-circle"
          />
        );
      }

      if (ApiRequest.can_access("reports", "can_view_virtual_card_report")) {
        reports_children.push(
          <Item
            key="virtual-basma-reports"
            text="Virtual Basma Reports"
            to="/reports/virtual_basma_reports"
            icon="fa-headset"
          />
        );
      }

      if (ApiRequest.can_access("reports", "can_view")) {
        reports_children.push(
          <Item
            key="offline_loyalty_rewards"
            text="Offline Loyalty Rewards"
            to="/reports/offline_loyalty_rewards"
            icon="fa-circle"
          />
        );
      }

      if (ApiRequest.can_access("reports", "can_view_users_report")) {
        reports_children.push(
          <Item
            key="limit_hitting_recording"
            text="Limit Hitting Recording"
            to="/reports/limit_hitting_recording"
            icon="fa-circle"
          />
        );
      }

      sidebar_menu.push(
        <Item
          key="reports"
          text="Reports"
          icon="fa-file-excel"
          children={reports_children}
        />
      );
    }
    if (ApiRequest.can_access("app_user", "can_view")) {
      sidebar_menu.push(
        <Item
          key="badges-statistics"
          text="Badges Statistics"
          to="/badges_statistics"
          icon="fa-archive"
        />
      );
    }
    if (ApiRequest.can_access("fuel-prices", "can_view")) {
      sidebar_menu.push(
        <Item
          key="fuel-prices"
          text="Fuel Prices"
          to="/fuel_prices"
          icon="fa-money-check-alt"
        />
      );
    }
    if (ApiRequest.can_access("setting", "can_view")) {
      sidebar_menu.push(
        <Item key="setting" text="Setting" to="/setting" icon="fa-cog" />
      );
    }
    // if (ApiRequest.can_access("news_integration")) {
    //   sidebar_menu.push(
    //     <Item
    //   key="news_integration"
    //   text="News Integration"
    //   to="/news_integration"
    //   icon="fa-tasks"
    // />
    //   );
    // }
    if (ApiRequest.can_access("admin-user", "can_view")) {
      sidebar_menu.push(
        <Item
          key="business_user_requests"
          text="Business User Requests"
          to="/business_user_requests"
          icon="fa-user"
        />
      );
    }
    if (
      ApiRequest.can_access("super-admin", "can_view") &&
      ApiRequest.can_access("app_user", "can_view")
    ) {
      sidebar_menu.push(
        <Item
          key="super-admin"
          text="Super Admin"
          to="/super_admin"
          icon="fa-user"
        />
      );
    }

    if (ApiRequest.can_access("station-user", "can_view")) {
      sidebar_menu.push(
        <Item
          key="super-admin"
          text="Station User"
          to="/station_user"
          icon="fa-user"
        />
      );
    }

    if (ApiRequest.can_access("admin-user", "can_view")) {
      sidebar_menu.push(
        <Item
          key="admin-user"
          text="Admin Users"
          to="/admin_user"
          icon="fa-user"
        />
      );
    }
    if (ApiRequest.can_access("app_user", "can_view")) {
      sidebar_menu.push(
        <Item key="app_user" text="App Users" to="/app_user" icon="fa-mobile" />
      );
    }
    if (ApiRequest.can_access("user-role", "can_view")) {
      sidebar_menu.push(
        <Item key="user-role" text="User Role" to="/roles" icon="fa-users" />
      );
    }
    if (ApiRequest.can_access("store", "can_view")) {
      sidebar_menu.push(
        <Item key="store" text="Stores" to="/store" icon="fa-building" />
      );
    }
    if (ApiRequest.can_access("old-cards", "can_view")) {
      sidebar_menu.push(
        <Item key="old_card" text="Old Cards" to="/old_card" icon="fa-tasks" />
      );
    }
    if (ApiRequest.can_access("basma-white-list", "can_view")) {
      sidebar_menu.push(
        <Item
          key="basma-white-list"
          text="Basma White List"
          to="/basma_white_list"
          icon="fa-list-alt"
        />
      );
    }

    if (ApiRequest.can_access("basma-cards-management", "can_view")) {
      sidebar_menu.push(
        <Item
          key="basma-cards-management"
          text="Basma Cards Management"
          to="/basma_cards_management"
          icon="fa-database"
        />
      );
    }
    if (ApiRequest.can_access("card-transaction-logs", "can_view")) {
      sidebar_menu.push(
        <Item
          key="card-transaction-logs"
          text="Card Transactions"
          to="/card_transaction"
          icon="fa-archive"
        />
      );
    }
    if (ApiRequest.can_access("rps-transaction-logs", "can_view")) {
      sidebar_menu.push(
        <Item
          key="rps-transaction-logs"
          text="RPS Transactions"
          to="/rps_transaction"
          icon="fa-archive"
        />
      );
    }
    if (ApiRequest.can_access("sfs-transaction", "can_view")) {
      sidebar_menu.push(
        <Item
          key="sfs-transaction"
          text="SFS Transactions"
          to="/sfs_transaction"
          icon="fa-archive"
        />
      );
    }
    if (ApiRequest.can_access("notifications", "can_view")) {
      sidebar_menu.push(
        <Item
          key="Notification"
          text="Notification"
          to="/notification"
          icon="fa-archive"
        />
      );
    }
    if (
      ApiRequest.can_access("oomcoFun", "") &&
      ApiRequest.can_access("app_user", "can_view")
    ) {
      var oomcoFun_children = [];

      if (ApiRequest.can_access("oomcoFun", "")) {
        oomcoFun_children.push(
          <Item
            key="question_bank"
            text="Question Bank"
            to="/oomco_fun/question_bank"
            icon="fa-circle"
          />
        );
      }
      if (ApiRequest.can_access("oomcoFun", "")) {
        oomcoFun_children.push(
          <Item
            key="quiz"
            text="Create Quiz"
            to="/oomco_fun/quiz"
            icon="fa-circle"
          />
        );
      }
      if (ApiRequest.can_access("oomcoFun", "")) {
        oomcoFun_children.push(
          <Item
            key="survey"
            text="Create Survey"
            to="/oomco_fun/survey"
            icon="fa-circle"
          />
        );
      }

      // if (ApiRequest.can_access("oomcoFun", "")) {
      //   oomcoFun_children.push(
      //     <Item
      //       key="ar_game"
      //       text="AR Game"
      //       to="/oomco_fun/ar_game"
      //       icon="fa-circle"
      //     />
      //   );
      // }
      if (ApiRequest.can_access("oomcoFun", "")) {
        oomcoFun_children.push(
          <Item
            key="spin_win"
            text="Spin & Win"
            to="/oomco_fun/spin_win"
            icon="fa-circle"
          />
        );
      }

      sidebar_menu.push(
        <Item
          key="oomcoFun"
          text="Oomco Fun"
          icon="fa-file-excel"
          children={oomcoFun_children}
        />
      );
    }
    if (
      ApiRequest.can_access("oomco_fun_reports", "") &&
      ApiRequest.can_access("app_user", "can_view")
    ) {
      var oomco_fun_reports_children = [];

      if (ApiRequest.can_access("oomco_fun_reports", "")) {
        oomco_fun_reports_children.push(
          <Item
            key="quiz_report"
            text="Quiz Report"
            to="/oomco_fun_reports/quiz_report"
            icon="fa-circle"
          />
        );
      }

      if (ApiRequest.can_access("oomco_fun_reports", "")) {
        oomco_fun_reports_children.push(
          <Item
            key="survey_report"
            text="Survey Report"
            to="/oomco_fun_reports/survey_report"
            icon="fa-circle"
          />
        );
      }
      if (ApiRequest.can_access("oomco_fun_reports", "")) {
        oomco_fun_reports_children.push(
          <Item
            key="spin_win_report"
            text="Spin and Win Report"
            to="/oomco_fun_reports/spin_win_report"
            icon="fa-circle"
          />
        );
      }
      sidebar_menu.push(
        <Item
          key="oomco_fun_reports"
          text="OomcoFun Reports"
          icon="fa-file-excel"
          children={oomco_fun_reports_children}
        />
      );
    }
    if (
      ApiRequest.can_access("voucher", "") &&
      ApiRequest.can_access("app_user", "can_view")
    ) {
      var fuel_promotion_children = [];
      if (ApiRequest.can_access("fuel-voucher", "can_view")) {
        fuel_promotion_children.push(
          <Item
            key="fuel_voucher"
            text="Fuel Voucher"
            to="/fuel_voucher"
            icon="fa-mobile"
          />
        );
      }
      if (ApiRequest.can_access("topup-promotion", "can_view")) {
        fuel_promotion_children.push(
          <Item
            key="topup_promotion"
            text="Topup Promotion"
            to="/topup_promotion"
            icon="fa-mobile"
          />
        );
      }
      if (ApiRequest.can_access("cashback-promotion", "can_view")) {
        fuel_promotion_children.push(
          <Item
            key="cash_back_promotion"
            text="Cash Back Promotion"
            to="/cash_back_promotion"
            icon="fa-mobile"
          />
        );
      }
      if (ApiRequest.can_access("promo-code", "can_view")) {
        fuel_promotion_children.push(
          <Item
            key="promo_code"
            text="Promo Code"
            to="/promo_code"
            icon="fa-mobile"
          />
        );
      }
      sidebar_menu.push(
        <Item
          key="fuel_promotion"
          text="Fuel Promotion"
          icon="fa-file-excel"
          children={fuel_promotion_children}
        />
      );
    }
    if (ApiRequest.can_access("fuel_promotion_reports", "can_view")) {
      var promotion_report_children = [];
      if (
        ApiRequest.can_access(
          "fuel_promotion_reports",
          "can_view_cashback_promotion_report"
        )
      ) {
        promotion_report_children.push(
          <Item
            key="cash_back_promotions_report"
            text="Cash Back Promotions"
            to="/cash_back_promotions_report"
            icon="fa-mobile"
          />
        );
      }
      if (
        ApiRequest.can_access(
          "fuel_promotion_reports",
          "can_view_online_topup_promotion_report"
        )
      ) {
        promotion_report_children.push(
          <Item
            key="online_topup_rewards"
            text="Online Top-up Rewards"
            to="/online_topup_rewards"
            icon="fa-mobile"
          />
        );
      }
      if (
        ApiRequest.can_access(
          "fuel_promotion_reports",
          "can_view_promo_code_report"
        )
      ) {
        promotion_report_children.push(
          <Item
            key="fuel_promotion_reports"
            text="Promo Codes"
            to="/promo_codes"
            icon="fa-mobile"
          />
        );
      }

      sidebar_menu.push(
        <Item
          key="promotion_report"
          text="Promotion Report"
          icon="fa-file-excel"
          children={promotion_report_children}
        />
      );
    }

    // if (ApiRequest.can_access("filter")) {
    //   sidebar_menu.push(<Item key="filter" text="Filter" to="/filter" />);
    // }
    if (
      ApiRequest.can_access("card-repository", "can_view") &&
      ApiRequest.can_access("app_user", "can_view")
    ) {
      sidebar_menu.push(
        <Item
          key="card_repository"
          text="Cards Repository"
          to="/card_repository"
          icon="fa-user"
        />
      );
    }
    if (
      ApiRequest.can_access("bulk-gift-card-activation-list", "can_view") &&
      ApiRequest.can_access("app_user", "can_view")
    ) {
      sidebar_menu.push(
        <Item
          key="bulk_gift_card_activation_list"
          text="Bulk Gift Card Activation List"
          to="/bulk_gift_card_activation_list"
          icon="fa-user"
        />
      );
    }

    if (
      ApiRequest.can_access("", "can_view") &&
      ApiRequest.can_access("app_user", "can_view")
    ) {
      sidebar_menu.push(
        <Item
          key="in-app-promotions"
          text="In-App Promotions"
          to="/in_app_promotions"
          icon="fa-user"
        />
      );
    }

    if (ApiRequest.can_access("card-repository", "can_view")) {
      var bar_code_children = [];

      if (ApiRequest.can_access("card-repository", "can_view")) {
        bar_code_children.push(
          <Item key="campaign " text="Campaign" to="/campaign" icon="fa-user" />
        );
      }

      if (ApiRequest.can_access("card-repository", "can_view")) {
        bar_code_children.push(
          <Item
            key="Product_bar_code"
            text="Product Barcode"
            to="/Product_bar_code"
            icon="fa-user"
          />
        );
      }

      sidebar_menu.push(
        <Item
          key="Bar Code"
          text="Barcode"
          icon="fa-file-excel"
          children={bar_code_children}
        />
      );
    }

    if (ApiRequest.can_access("partner-user", "can_add")) {
      sidebar_menu.push(
        <Item
          key="partner_listing"
          text="Partner Listing"
          to="/partner_listing"
          icon="fa-user"
        />
      );
    }

    // if (ApiRequest.can_access("partner-user", "can_view")) {
    //   sidebar_menu.push(
    //     <Item
    //       key="partner_report"
    //       text="Partner Report"
    //       to="/partner_report"
    //       icon="fa-user"
    //     />
    //   );
    // }

    // if (ApiRequest.can_access("partner", "can_view")) {
    //   var partner_children = [];

    //   if (ApiRequest.can_access("partner-list", "can_view")) {
    //     partner_children.push(
    //       <Item
    //         key="partner_listing"
    //         text="Partner Listing"
    //         to="/partner_listing"
    //         icon="fa-user"
    //       />
    //     );
    //   }

    //   if (ApiRequest.can_access("partner-report", "can_view")) {
    //     partner_children.push(
    //       <Item
    //         key="partner_report"
    //         text="Partner Report"
    //         to="/partner_report"
    //         icon="fa-user"
    //       />
    //     );
    //   }

    //   sidebar_menu.push(
    //     <Item
    //       key="Partner"
    //       text="Partner"
    //       icon="fa-file-excel"
    //       children={partner_children}
    //     />
    //   );
    // }

    // if (ApiRequest.can_access("", "can_view")) {
    //   sidebar_menu.push(
    //     <Item key="qr_code " text="QR Code" to="/qr_code" icon="fa-user" />
    //   );
    // }

    if (ApiRequest.can_access("insurer", "can_add")) {
      sidebar_menu.push(
        <Item
          key="my-garage "
          text="My Garage"
          to="/my-garage"
          icon="fa-user"
        />
      );
    }

    if (ApiRequest.can_access("insurer", "can_view")) {
      sidebar_menu.push(
        <Item
          key="insurances "
          text="Insurances"
          to="/insurances"
          icon="fa-user"
        />
      );
    }

    if (ApiRequest.can_access("super-admin", "can_view")) {
      var stamp_card_children = [];

      if (ApiRequest.can_access("super-admin", "can_view")) {
        stamp_card_children.push(
          <Item
            key="stamp_card"
            text="Add Stamp"
            to="/stamp_card"
            icon="fa-user"
          />
        );
      }
      if (ApiRequest.can_access("super-admin", "can_view")) {
        stamp_card_children.push(
          <Item
            key="upload_voucher"
            text="Upload Voucher Code"
            to="/upload_voucher"
            icon="fa-user"
          />
        );
      }
      sidebar_menu.push(
        <Item
          key="stamp_card_children"
          text="Stamp Card"
          icon="fa-file-excel"
          children={stamp_card_children}
        />
      );
    }

    if (ApiRequest.can_access("super-admin", "can_view")) {
      sidebar_menu.push(
        <Item key="nfc " text="NFC" to="/nfc" icon="fa-user" />
      );
    }
    if (ApiRequest.can_access("super-admin", "can_view")) {
      sidebar_menu.push(
        <Item key="nfc " text="NFC Report" to="/nfc_report" icon="fa-user" />
      );
    }

    return sidebar_menu;
  }
  render() {
    if (this.state.is_login && this.state.permissions)
      return (
        <div>
          <DownloadModal />
          <AdminLTE
            title={["OOMCO"]}
            titleShort={["OMC"]}
            theme="blue"
            browserTitle={"OOMCO"}
            sidebar={this.sidebarMenu()}
          >
            <DownloadModal />
            <Navbar.Core>
              <Entry icon="fas-power-off" onClick={this.handleClick} />
            </Navbar.Core>
            <Dashboard path="/" exact />
            <Listing path="/components" exact />
            {ApiRequest.can_access("cms-pages", "can_view") && (
              <CMS path="/cms" exact />
            )}
            {ApiRequest.can_access("station-review", "can_view") && (
              <StationReview path="/station_reviews" exact />
            )}
            {ApiRequest.can_access("station-review", "can_view_single") && (
              <StationReview path="/station_reviews/:id" exact view={true} />
            )}
            {ApiRequest.can_access("station", "can_view") && (
              <StationLocator path="/stations" exact />
            )}
            {ApiRequest.can_access("station", "can_view_single") && (
              <StationLocator path="/stations/:id" exact view_station={true} />
            )}
            {ApiRequest.can_access("basma-white-list", "can_view") && (
              <StationLoyaltyDistribution
                path="/stations_loyalty_distributions"
                exact
              />
            )}
            {ApiRequest.can_access("basma-white-list", "can_view") && (
              <StationLoyaltyDistributionForm
                path="/stations_loyalty_distributions_form/"
                exact
                add={true}
              />
            )}
            {ApiRequest.can_access("basma-white-list", "can_view") && (
              <StationLoyaltyDistributionForm
                path="/stations_loyalty_distributions_form/edit/:id"
                exact
                edit={true}
              />
            )}
            {ApiRequest.can_access("admin-user", "can_view") && (
              <StationLoyaltyDistributionReport
                path="/stations_loyalty_distributions_report"
                exact
              />
            )}
            {ApiRequest.can_access("station", "can_view") && (
              <SelfServiceNozzles path="/self_service_nozzles" exact />
            )}
            {ApiRequest.can_access("station", "can_view") && (
              <SelfServiceNozzlesForm
                path="/self_service_nozzles_form/"
                exact
                add={true}
              />
            )}
            {ApiRequest.can_access("station", "can_view") && (
              <SelfServiceNozzlesForm
                path="/self_service_nozzles_form/edit/:id"
                exact
                edit={true}
              />
            )}
            {ApiRequest.can_access("safety-tips", "can_view") && (
              <SafetyTip path="/safety_tips" exact />
            )}
            {ApiRequest.can_access("contact-form", "can_view") && (
              <ContactForm path="/contact" exact />
            )}
            {ApiRequest.can_access("contact-form", "can_view_single") && (
              <ContactForm path="/contact/:id" exact view={true} />
            )}
            {ApiRequest.can_access("complaint-management", "can_view") && (
              <Complaint path="/complaint" exact />
            )}
            {ApiRequest.can_access(
              "complaint-management",
              "can_view_single"
            ) && <Complaint path="/complaint/:id" exact view={true} />}
            {/* <ComplaintType path="/complaint_type" exact /> */}
            {ApiRequest.can_access("fuel-prices", "can_view") && (
              <FuelPrices path="/fuel_prices" exact />
            )}
            <FuelForm path="/fuel_prices/edit/:id" exact edit={true} />
            {ApiRequest.can_access("admin-user", "can_view") && (
              <AdminUser path="/admin_user" exact />
            )}
            {ApiRequest.can_access("admin-user", "can_view_single") && (
              <AdminUserView path="/admin_user_view/:id" exact view={true} />
            )}
            {ApiRequest.can_access("admin-user", "can_add") && (
              <AdminUserForm path="/admin_user_form/" add={true} />
            )}
            {ApiRequest.can_access("admin-user", "can_edit") && (
              <AdminEditForm path="/admin_user_edit/:id" edit={true} />
            )}
            {ApiRequest.can_access("admin-user", "can_delete") && (
              <UserDelete path="/user_delete/:id" />
            )}
            {ApiRequest.can_access("app_user", "can_view") && (
              <AppUser path="/app_user" exact />
            )}
            {ApiRequest.can_access("app_user", "can_view_single") && (
              <AppView path="/app_view/:id" exact view={true} />
            )}
            {ApiRequest.can_access("user-role", "can_view") && (
              <Roles path="/roles" exact />
            )}
            <RolesForm path="/user-role/edit/:id" exact edit={true} />
            {ApiRequest.can_access("basma-cards-management", "can_view") && (
              <BasmaManagement path="/basma_cards_management" exact />
            )}
            {ApiRequest.can_access("basma-cards-management", "can_topup") && (
              <CardTopUp path="/card_top_up/:id" />
            )}
            {ApiRequest.can_access(
              "basma-cards-management",
              "can_deduct_amount"
            ) && <CardTopUpReverse path="/card_top_up_reverse/:id" />}
            {ApiRequest.can_access("basma-white-list", "can_view") && (
              <WhiteList path="/basma_white_list" exact />
            )}
            {ApiRequest.can_access("basma-white-list", "can_view_single") && (
              <WhiteList path="/basma_white_list/:id" exact view={true} />
            )}
            {ApiRequest.can_access("basma-white-list", "can_view_single") && (
              <Card path="/card/:id" />
            )}
            {ApiRequest.can_access("basma-white-list", "can_view") && (
              <OnboardingUrlGenerator path="/onboarding-url-generator" exact />
            )}
            <CardReplace path="/card_replace/:id" />
            {ApiRequest.can_access("basma-white-list", "can_activate") && (
              <ActiveCard path="/active_card/:id" />
            )}
            {ApiRequest.can_access("basma-white-list", "can_assign_store") && (
              <AssignStore path="/assign_store/:id" />
            )}
            {ApiRequest.can_access("old-cards", "can_view") && (
              <OldCard path="/old_card" exact />
            )}
            {ApiRequest.can_access("setting", "can_view") && (
              <Setting path="/setting" exact />
            )}
            {ApiRequest.can_access("store", "can_view") && (
              <Store path="/store" exact />
            )}
            {ApiRequest.can_access("store", "can_view_single") && (
              <Store path="/store/view/:id" exact view={true} />
            )}
            {ApiRequest.can_access("store", "can_edit") && (
              <Store path="/store/edit/:id" exact edit={true} />
            )}
            {ApiRequest.can_access("card-transaction-logs", "can_view") && (
              <CardTransaction path="/card_transaction" exact />
            )}
            {ApiRequest.can_access(
              "card-transaction-logs",
              "can_view_single"
            ) && <CardTransactionView path="/card_transactions_view/:id" />}
            <BadgesStatistics path="/badges_statistics" exact />
            <BadgesStatisticsView path="/badges_statistics_view/:id" />
            {ApiRequest.can_access("rps-transaction-logs", "can_view") && (
              <RpsTransaction path="/rps_transaction" exact />
            )}
            {ApiRequest.can_access(
              "rps-transaction-logs",
              "can_view_single"
            ) && <RpsTransactionView path="/rps_transaction_view/:id" />}
            {ApiRequest.can_access("sfs-transaction", "can_view") && (
              <SfsTransaction path="/sfs_transaction" exact />
            )}
            {ApiRequest.can_access("sfs-transaction", "can_view_single") && (
              <SfsTransactionView path="/sfs_transaction_view/:id" />
            )}
            {/*<Filter path="/filter/" exact />*/}
            {ApiRequest.can_access("reports", "can_view_cards_report") && (
              <CardInventory path="/reports/card_inventory/" />
            )}
            {ApiRequest.can_access("reports", "can_view_sales_report") && (
              <CardSalesReport path="/reports/card_sales_report/" />
            )}
            {ApiRequest.can_access(
              "reports",
              "can_view_card_transactions_report"
            ) && (
              <CardTransactionsReports path="/reports/card_transactions_reports/" />
            )}
            {ApiRequest.can_access("reports", "can_view_users_report") && (
              <UserReport path="/reports/users_reports/" />
            )}
            {ApiRequest.can_access(
              "reports",
              "can_view_refueling_transaction_report"
            ) && (
              <RefuelingTransaction path="/reports/refueling_transaction/" />
            )}
            {ApiRequest.can_access(
              "reports",
              "can_view_store_transaction_report"
            ) && (
              <StoresTransactionsReport path="/reports/stores_transactions_report/" />
            )}
            {ApiRequest.can_access(
              "reports",
              "can_view_epayment_transaction_report"
            ) && (
              <EPaymentTransaction path="/reports/ePayment_transactions_report/" />
            )}
            {ApiRequest.can_access(
              "reports",
              "can_view_epayment_transaction_report"
            ) && (
              <ThawaniTransactions path="/reports/thawani_transactions_report/" />
            )}
            {(ApiRequest.can_access(
              "reports",
              "can_view_epayment_transaction_report"
            ) ||
              ApiRequest.can_access("partner-user", "can_view")) && (
              <MakasibTransactions path="/reports/partner_topup_transactions_report/" />
            )}
            {ApiRequest.can_access(
              "reports",
              "can_view_virtual_card_report"
            ) && <VirtualBasmaReports path="/reports/virtual_basma_reports/" />}

            {ApiRequest.can_access("reports", "can_view") && (
              <OfflineLoyaltyRewards path="/reports/offline_loyalty_rewards" />
            )}

            {ApiRequest.can_access("reports", "can_view_users_report") && (
              <LimitHittingRecording path="/reports/limit_hitting_recording/" />
            )}

          {ApiRequest.can_access("reports", "can_view_users_report") && (
            <LimitHitView path="/reports/limit_hitting_view/:id" />
          )}

            {/*{ApiRequest.can_access(
            "reports",
            "can_view_card_transactions_reports"
          ) && <CardTransactionsReports path="/card_transactions_reports/" />}
          f
           {ApiRequest.can_access("reports", "can_view_users_report") && (
             <UserReport path="/users_reports/" />
          )}
          {ApiRequest.can_access(
            "reports",
            "can_view_refueling_transaction"
          ) && <RefuelingTransaction path="/refueling_transaction/" />}
          {ApiRequest.can_access(
            "reports",
            "can_view_stores_transactions_report"
          ) && <StoresTransactionsReport path="/stores_transactions_report/" />*/}

            {ApiRequest.can_access("notifications", "can_view") && (
              <Notification path="/notification" exact />
            )}
            {ApiRequest.can_access("question_bank", "can_view") && (
              <QuestionBank path="/oomco_fun/question_bank/" />
            )}
            {ApiRequest.can_access("question_bank", "can_view") && (
              <QuestionBankView
                path="/question_bank_view/:id"
                exact
                view={true}
              />
            )}
            {ApiRequest.can_access("question_bank", "can_add") && (
              <QuestionBankForm path="/question_bank_form/" add={true} />
            )}
            {ApiRequest.can_access("question_bank", "can_edit") && (
              <QuestionEditForm path="/question_bank_edit/:id" edit={true} />
            )}
            {ApiRequest.can_access("quiz", "can_view") && (
              <Quiz path="/oomco_fun/quiz/" />
            )}
            {ApiRequest.can_access("quiz", "can_add") && (
              <QuizForm path="/quiz_form/" add={true} />
            )}
            {ApiRequest.can_access("quiz", "can_edit") && (
              <QuizFormEdit path="/quiz_form_edit/:id" edit={true} />
            )}
            {ApiRequest.can_access("quiz", "can_view") && (
              <QuizView path="/quiz_form_view/:id" edit={true} />
            )}
            {ApiRequest.can_access("survey", "can_view") && (
              <Survey path="/oomco_fun/survey/" />
            )}
            {ApiRequest.can_access("survey", "can_add") && (
              <SurveyForm path="/survey_form/" add={true} />
            )}
            {ApiRequest.can_access("survey", "can_edit") && (
              <SurveyFormEdit path="/survey_form_edit/:id" edit={true} />
            )}
            {ApiRequest.can_access("survey", "can_view") && (
              <SurveyView path="/survey_view/:id" edit={true} />
            )}
            {ApiRequest.can_access("oomcoFun", "can_view") && (
              <ARGame path="/oomco_fun/ar_game/" />
            )}
            {ApiRequest.can_access("spin_and_win", "can_view") && (
              <SpinWin path="/oomco_fun/spin_win/" />
            )}
            {ApiRequest.can_access("spin_and_win", "can_view") && (
              <SpinWinView path="/spin_win_view/:id" exact view={true} />
            )}
            {ApiRequest.can_access("spin_and_win", "can_add") && (
              <SpinWinForm path="/spin_win_form/" add={true} />
            )}
            {ApiRequest.can_access("spin_and_win", "can_edit") && (
              <SpinWinEditForm path="/spin_win_edit/:id" edit={true} />
            )}
            {ApiRequest.can_access("oomco_fun_reports", "") && (
              <QuizReport path="/oomco_fun_reports/quiz_report/" />
            )}
            {ApiRequest.can_access("oomco_fun_reports", "") && (
              <SurveyReport path="/oomco_fun_reports/survey_report/" />
            )}
            {ApiRequest.can_access("oomco_fun_reports", "") && (
              <SpinWinReport path="/oomco_fun_reports/spin_win_report/" />
            )}
            {ApiRequest.can_access("fuel-voucher", "can_view") && (
              <FuelVoucher path="/fuel_voucher/" />
            )}
            {/*{ApiRequest.can_access("fuel_voucher", "") && (
            <FuelVoucherView path="/fuel_voucher_view/:id" exact view={true} />
          )}*/}
            {ApiRequest.can_access("fuel-voucher", "can_add") && (
              <FuelVoucherForm path="/fuel_voucher_form/" add={true} />
            )}
            {ApiRequest.can_access("fuel-voucher", "can_edit") && (
              <FuelVoucherEditForm path="/fuel_voucher_edit/:id" edit={true} />
            )}

            {ApiRequest.can_access("topup-promotion", "can_view") && (
              <TopupPromotion path="/topup_promotion/" />
            )}
            {ApiRequest.can_access("topup-promotion", "can_view") && (
              <TopupPromotionView
                path="/topup_promotion_view/:id"
                exact
                view={true}
              />
            )}
            {ApiRequest.can_access("topup-promotion", "can_add") && (
              <TopupPromotionForm path="/topup_promotion_form/" add={true} />
            )}
            {ApiRequest.can_access("topup-promotion", "can_edit") && (
              <TopupPromotionEditForm
                path="/topup_promotion_edit/:id"
                edit={true}
              />
            )}

            {ApiRequest.can_access("cashback-promotion", "can_view") && (
              <CashBackPromotion path="/cash_back_promotion/" />
            )}
            {ApiRequest.can_access("cashback-promotion", "can_view") && (
              <CashBackPromotionView
                path="/cash_back_promotion_view/:id"
                exact
                view={true}
              />
            )}
            {ApiRequest.can_access("cashback-promotion", "can_add") && (
              <CashBackPromotionForm
                path="/cash_back_promotion_form/"
                add={true}
              />
            )}
            {ApiRequest.can_access("cashback-promotion", "can_edit") && (
              <CashBackPromotionEditForm
                path="/cash_back_promotion_edit/:id"
                edit={true}
              />
            )}

            {ApiRequest.can_access("promo-code", "can_view") && (
              <PromoCode path="/promo_code/" />
            )}
            {/*{ApiRequest.can_access("promo-code", "can_view") && (
            <PromoCodeView path="/promo_code_view/:id" exact view={true} />
          )}*/}
            {ApiRequest.can_access("promo-code", "can_add") && (
              <PromoCodeForm path="/promo_code_form/" add={true} />
            )}
            {ApiRequest.can_access("promo-code", "can_edit") && (
              <PromoCodeEditForm path="/promo_code_edit/:id" edit={true} />
            )}

            {ApiRequest.can_access("cash_back_promotion_report", "") && (
              <CashBackPromotionsReport path="/cash_back_promotions_report/" />
            )}

            {ApiRequest.can_access("online_topup_rewards", "") && (
              <OnlineTopupRewardsReport path="/online_topup_rewards/" />
            )}

            {ApiRequest.can_access("promo_codes", "") && (
              <PromoCodesReport path="/promo_codes/" />
            )}

            {ApiRequest.can_access("", "can_view") && (
              <InAppPromotions path="/in_app_promotions/" />
            )}
            {ApiRequest.can_access("", "can_add") && (
              <InAppPromotionsForm path="/in_app_promotions_form/" add={true} />
            )}
            {/*{ApiRequest.can_access("spin_and_win", "can_view") && (
            <SpinWinView path="/spin_win_view/:id" exact view={true} />
          )}*/}

            {ApiRequest.can_access("oomco_cards", "can_add") && (
              <BulkBlankCardActivationForm
                path="/bulk_blank_card_activation_form/"
                add={true}
              />
            )}
            {ApiRequest.can_access("oomco_cards", "can_add") && (
              <BulkGiftCardActivationList
                path="/bulk_gift_card_activation_list/"
                add={true}
              />
            )}
            {/*  {ApiRequest.can_access("cashback-promotion", "can_view") && (
            <CashBackPromotionView
              path="/cash_back_promotion_view/:id"
              exact
              view={true}
            />
          )}
          {ApiRequest.can_access("cashback-promotion", "can_edit") && (
            <CashBackPromotionEditForm
              path="/cash_back_promotion_edit/:id"
              edit={true}
            />
          )}*/}

            {ApiRequest.can_access("oomco_cards", "") && (
              <CardRepository path="/card_repository/" />
            )}
            {ApiRequest.can_access("oomco_cards", "") && (
              <CardRepositoryForm path="/card_repository_form/" add={true} />
            )}
            {ApiRequest.can_access("oomco_cards", "") && (
              <BulkGiftCardActivationForm
                path="/bulk_gift_card_activation_form/"
                add={true}
              />
            )}

            {ApiRequest.can_access("", "can_edit") && (
              <InAppPromotionsEditForm
                path="/in_app_promotions_edit/:id"
                edit={true}
              />
            )}

            {ApiRequest.can_access("super-admin", "can_view") && (
              <SuperAdminListing path="/super_admin/" />
            )}

            {ApiRequest.can_access("station", "can_view") && (
              <BusinessUserRequests path="/business_user_requests" exact />
            )}

          {ApiRequest.can_access("station", "can_view") && (
            <BusinessUserRequestsView path="/business_user_requests_view/:id" />
          )}

          {ApiRequest.can_access("station", "can_view") && (
            <BusinessUserRequestsView path="/business_user_requests_view/:id" />
          )}

          {ApiRequest.can_access("station-user", "can_view") && (
            <StationUserListing path="/station_user/" />
          )}

            {ApiRequest.can_access("card-repository", "can_view") && (
              <AddBadge path="/add_badge/" add={true} />
            )}

            {ApiRequest.can_access("card-repository", "can_view") && (
              <EditBadge path="/badge_edit/:id" edit={true} />
            )}

            {ApiRequest.can_access("card-repository", "can_view") && (
              <Campaign path="/campaign/" />
            )}

            {ApiRequest.can_access("card-repository", "can_view") && (
              <Campaign_form path="/campaign_form/" add={true} />
            )}

            {ApiRequest.can_access("card-repository", "can_view") && (
              <Campaign_view path="/campaign_view/:id" add={true} />
            )}

            {ApiRequest.can_access("card-repository", "can_view") && (
              <Product_bar_code path="/product_bar_code/" add={true} />
            )}

            {ApiRequest.can_access("card-repository", "can_view") && (
              <Product_bar_code_form
                path="/product_bar_code_form/"
                add={true}
              />
            )}
            {ApiRequest.can_access("", "") && (
              <Partner path="/partner_listing/" />
            )}
            {ApiRequest.can_access("", "") && (
              <Partner_form path="/partner_form/" add={true} />
            )}
            {ApiRequest.can_access("", "can_edit") && (
              <PartnerEditForm path="/partner_form_edit/:id" edit={true} />
            )}
            {ApiRequest.can_access("", "") && (
              <PartnerView path="/partner_view/:id" view={true} />
            )}

            {/* {ApiRequest.can_access("", "") && (
            <PartnerReport path="/partner_report/" />
          )}
          {ApiRequest.can_access("", "") && (
            <PartnerReportView path="/partner_report_view/:id" view={true} />
          )} */}

            {ApiRequest.can_access("", "") && <MyGarage path="/my-garage/" />}
            {ApiRequest.can_access("", "") && (
              <MyGarageView path="/my_garage_view/:id" view={true} />
            )}

            {ApiRequest.can_access("", "") && (
              <InsurancesReport path="/insurances/" />
            )}

            {ApiRequest.can_access("super-admin", "can_view") && (
              <StampCard path="/stamp_card" />
            )}
            {ApiRequest.can_access("super-admin", "can_view") && (
              <StampCardAdd path="/stamp_card_add" />
            )}
            {ApiRequest.can_access("super-admin", "can_view") && (
              <StampCardEdit path="/stamp_card_edit/:id" edit={true} />
            )}

            {ApiRequest.can_access("super-admin", "can_view") && (
              <UploadVoucher path="/upload_voucher" />
            )}
            {ApiRequest.can_access("super-admin", "can_view") && (
              <UploadVoucherAdd path="/upload_voucher_add" />
            )}
            {ApiRequest.can_access("super-admin", "can_view") && (
              <UploadVoucherEdit path="/upload_voucher_edit/:id" edit={true} />
            )}

            {ApiRequest.can_access("super-admin", "can_view") && (
              <NfcList path="/nfc/" />
            )}
            {ApiRequest.can_access("super-admin", "can_view") && (
              <NfcReport path="/nfc_report/" />
            )}

            <UpdateVehicle path="/update_vehicle/:id" />
          </AdminLTE>
        </div>
      );

    return <Login />;
  }
}

export default App;
