import React from "react";
import { Content, Row, Col } from "adminlte-2-react";
import ApiRequest from "../../ApiRequest";
import API from "../../constants";
import LoadingSpinner from "../../components/LoadingSpinner";
import Pagination from "react-js-pagination";
//import { CSVLink } from "react-csv";
import LoadingAjax from "../../components/Loading";
import Select from "react-select";

require("bootstrap/less/bootstrap.less");

const cashBack_url = API.apiEndpoint + API.CashBackReport;
const cashBackPromotion_url = API.apiEndpoint + API.CashBackPromotions;

export default class CashBackPromotionsReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      cashBack: [],
      pagination: {},
      activePage: 1,
      error: false,
      message: "",
      loading: false,
      limit: 10,
      callApi: false,
      enableBtn: false,
      loadingCart: false,
      tiers: [],
    };
  }

  handleSubmit = async (event) => {
    await this.getReportData();
  };
  handlePageChange = async (pageNumber) => {
    await this.getReportData(pageNumber);
  };
  getReportData = async (pageNumber = 1) => {
    this.setState({ loading: true, loadingCart: true });

    let url = cashBack_url + "?page=" + pageNumber;

    if (this.state.cashBackPromotion_id) {
      url =
        url +
        "&filter=true&key=promotion_id&value=" +
        this.state.cashBackPromotion_id;
    }

    let options = { url: url };
    let api_response = await ApiRequest.get(options);

    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        cashBackCard: api_response.data,
        cashBack: api_response.data.results,
        pagination: api_response.data.pagination,
        loading: false,
        error: false,
        message: api_response.message,
        callApi: true,
      });
    }

    this.setState({
      loadingCart: false,
    });
  };
  downloadData = async (pageNumber = 1) => {
    let url = cashBack_url + "?csv=" + 1;

    if (this.state.cashBackPromotion_id) {
      url = url + "&cashBackPromotion_id=" + this.state.cashBackPromotion_id;
    }

    if (this.state.tierSelected) {
      url = url + "&tierSelected=" + this.state.tierSelected;
    }

    let options = {
      url: url,
      file_name:
        "Cash Back Promotions Report - " +
        ApiRequest.date_format_utc(Date.now()) +
        ".csv",
    };

    await ApiRequest.download(options);
  };
  async componentDidMount() {
    //await this.getReportData();

    let options = { url: cashBack_url };
    let api_response = await ApiRequest.get(options);

    let cashBackPromotion_options = {
      url: cashBackPromotion_url + "?allRecords=1",
    };
    let cashBackPromotion_options_response = await ApiRequest.get(
      cashBackPromotion_options
    );
    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        cashBackTierName: cashBackPromotion_options_response.data.results,
        cashBackCard: api_response.data,
        cashBack: api_response.data.results,
        pagination: api_response.data.pagination,
        loading: false,
        error: false,
        message: api_response.message,
      });
      if (this.callApi) {
        let api_response = await ApiRequest.get(options);
        this.setState({
          cashBackCard: api_response.data,
          cashBack: api_response.data.results,
          pagination: api_response.data.pagination,
        });
      }
    }
  }
  rescue_value(value) {
    return value === undefined ? "-" : value;
  }
  rescue_status(value) {
    return value === 1 ? "Active" : "Inactive";
  }

  handleTierChange = (event) => {
    this.setState({
      cashBackPromotion_id: event.target.value,
      enableBtn: true,
    });
  };
  noResultText() {
    return "N/A";
  }
  renderLoader() {
    return <LoadingSpinner />;
  }

  render() {
    const {
      pagination,
      cashBack,
      cashBackCard,
      loading,
      loadingCart,
      error,
      limit,
      message,
      callApi,
      enableBtn,
      cashBackTierName,
    } = this.state;
    return (
      <Content
        title="Cash Back Promotions Report"
        browserTitle="Cash Back Promotions Report"
      >
        <LoadingAjax />
        <Row>
          {error && (
            <div
              className="alert alert-danger alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {message}
            </div>
          )}
          <Col xs={12}>
            <div className="box box-primary">
              <div className="box-header with-border box-table-cus">
                <h3 className="box-title">Report</h3>
              </div>
              <div
                className="row clearfix"
                style={{ marginTop: "5px", padding: "10px" }}
              >
                {/*<div className="col-xs-4">
                  <div className="form-group rangeFilter">
                    <label>Sale Date</label>
                    <DatePicker
                      dateFormat="MM/dd/yyyy"
                      selected={this.state.sale_date_from}
                      selectsStart
                      sale_date_from={this.state.sale_date_from}
                      sale_date_to={this.state.sale_date_to}
                      onChange={this.saleStart}
                      placeholderText="From"
                    />
                    <DatePicker
                      dateFormat="MM/dd/yyyy"
                      selected={this.state.sale_date_to}
                      selectsEnd
                      sale_date_from={this.state.sale_date_from}
                      sale_date_to={this.state.sale_date_to}
                      onChange={this.saleEnd}
                      minDate={this.state.sale_date_from}
                      placeholderText="To"
                    />
                  </div>
                </div>
                <div className="col-xs-4">
                  <div className="form-group">
                    <label>Activation Source </label>
                    <select
                      className="form-control"
                      value={this.state.activation_source}
                      onChange={this.handleActivationSource}
                    >
                      <option value={null}>Select</option>
                      <option value="Admin">Admin</option>
                      <option value="Ahlain">Ahlain</option>
                      <option value="MobileApp">Mobile App</option>
                      <option value=" RegionalHoOffices">
                        Regional/Ho Offices
                      </option>
                    </select>
                  </div>
                </div>
                <div className="col-xs-4 cusSelect">
                  <Select2
                    label="Region"
                    value={this.state.selectedRegion}
                    onChange={this.handleChangeRegion}
                    options={cities}
                    placeholder="Select"
                  />
                </div>
                <div className="col-xs-4 cusSelect">
                  <Select2
                    label="Stores"
                    className="form-control"
                    value={this.state.selectedOption}
                    onChange={this.handleChangeStoreName}
                    options={tiers}
                    placeholder="Select"
                  />
          </div>*/}
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="tierTarget">Name</label>

                    <select
                      className="form-control"
                      id="loyaltyGameId"
                      value={this.state.selectedTierNameOption}
                      onChange={this.handleTierChange}
                    >
                      <option value=""> Select Name</option>
                      {cashBackTierName &&
                        cashBackTierName.map((data) => {
                          return (
                            <option value={data.id}>
                              {data.name ? data.name : "No Name"}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div className="col-xs-3"></div>
                <div className="text-right col-xs-12">
                  <button
                    onClick={this.handleSubmit}
                    className="btn btn-primary"
                    disabled={!enableBtn}
                  >
                    Submit
                  </button>
                </div>
              </div>
              <div className="box-body">
                <div className="row">
                  <div className="col-md-4 col-sm-6">
                    <div className="info-box-custom">
                      <div className="info-box-content-custom">
                        <h4 className="info-box-text-custom">
                          Total Cash-Back Transactions
                        </h4>
                        {loadingCart ? (
                          <LoadingSpinner />
                        ) : cashBackCard ? (
                          <div>
                            <span className="info-box-number-custom">
                              {cashBackCard.totalCashBackTransactions}
                            </span>
                          </div>
                        ) : (
                          <div>{this.noResultText()}</div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="info-box-custom">
                      <div className="info-box-content-custom">
                        <h4 className="info-box-text-custom">
                          Value of Transactions (Refueling)
                        </h4>
                        {loadingCart ? (
                          <LoadingSpinner />
                        ) : cashBackCard ? (
                          <div>
                            <span className="info-box-number-custom">
                              {cashBackCard.valueOfTransactions}
                            </span>
                          </div>
                        ) : (
                          <div>{this.noResultText()}</div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="info-box-custom">
                      <div className="info-box-content-custom">
                        <h4 className="info-box-text-custom">
                          {" "}
                          Value of Cash-Back Transactions
                        </h4>
                        {loadingCart ? (
                          <LoadingSpinner />
                        ) : cashBackCard ? (
                          <div>
                            <span className="info-box-number-custom">
                              {cashBackCard.valueOfCashBackTransactions}
                            </span>
                          </div>
                        ) : (
                          <div>{this.noResultText()}</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>

          <Col xs={12}>
            <div className="box box-primary">
              <div className="box-header with-border box-table-cus">
                <h3 className="box-title">Report</h3>
                {/*<div className="box-tools">
                  <button
                    className="btn btn-sm btn-primary "
                    onClick={this.downloadData}
                  >
                    Export
                  </button>
                        </div>*/}
              </div>
              <div className="box-body">
                <div className="table-responsive">
                  {loading ? (
                    <LoadingSpinner />
                  ) : (
                    <div>
                      <table
                        className="table no-margin"
                        id="export-table-card-sale"
                      >
                        <thead>
                          <tr>
                            <th>Card Serial Number</th>
                            <th>Date/time</th>
                            <th>Transaction Value OMR (refueling)</th>
                            <th>Volume (Liters)</th>
                            <th>Promotion Name</th>
                            <th>Cash back value (OMR)</th>
                            <th>OOMCO ID</th>
                          </tr>
                        </thead>

                        <tbody>
                          {cashBack.length > 0 && cashBack ? (
                            cashBack.map((data) => {
                              return (
                                <tr key={data.id}>
                                  <td>
                                    {
                                      <span>
                                        <a href={"/card/" + data.card_number}>
                                          {this.rescue_value(data.card_number)}
                                        </a>
                                      </span>
                                    }
                                  </td>
                                  <td>
                                    {ApiRequest.date_time_format(
                                      this.rescue_value(data.createdAt)
                                    )}
                                  </td>
                                  <td>
                                    {this.rescue_value(data.transactionAmount)}
                                  </td>

                                  <td>{this.rescue_value(data.volume)}</td>
                                  <td>
                                    {
                                      <span>
                                        <a
                                          href={
                                            "/cash_back_promotion_view/" +
                                            data.promotion_id
                                          }
                                        >
                                          {data.promotion?.name
                                            ? this.rescue_value(
                                                data.promotion.name
                                              )
                                            : " No Name "}
                                        </a>
                                      </span>
                                    }
                                  </td>
                                  <td>
                                    {this.rescue_value(data.cashbackAwarded)}
                                  </td>
                                  <td>
                                    {
                                      <span>
                                        <a href={"/app_view/" + data.user_id}>
                                          {this.rescue_value(data.oomco_id)}
                                        </a>
                                      </span>
                                    }
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <td colSpan="11" className="noRecord">
                              {callApi ? "No Record Found" : "No Record Found"}
                            </td>
                          )}
                        </tbody>
                      </table>
                      {pagination.size > 0 && (
                        <div className="tableFooter">
                          <div>
                            <div className="dataTables_info">
                              Showing {(pagination.currentPage - 1) * limit + 1}{" "}
                              to {pagination.currentPage * limit} of{" "}
                              {pagination.size} entries
                            </div>
                          </div>
                          <div style={{ textAlign: "right" }}>
                            <Pagination
                              prevPageText={"<"}
                              nextPageText={">"}
                              activePage={pagination.currentPage}
                              itemsCountPerPage={pagination.limit}
                              totalItemsCount={pagination.size}
                              pageRangeDisplayed={5}
                              onChange={this.handlePageChange}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Content>
    );
  }
}
