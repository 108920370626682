import React from "react";
import { Content, Row, Col } from "adminlte-2-react";
import axios from "axios";
import API from "./../../constants";
import ApiRequest from "./../../ApiRequest";
import LoadingSpinner from "../../components/LoadingSpinner";
import Pagination from "react-js-pagination";
import LoadingAjax from "../../components/Loading";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Modal from "react-modal";
import moment from "moment";
const whiteList_url = API.apiEndpoint + API.whiteList;
const postWhiteList_url = API.apiEndpoint + API.uploadWhiteList;
const cardOperation_url = API.apiEndpoint + API.cardOperation;
const referenceNumber_url = API.apiEndpoint + API.referenceNumber;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
  },
};

export default class WhiteListListing extends React.Component {
  state = {
    value: "optionOne",
    whiteList: [],
    loading: true,
    error: false,
    success: false,
    message: "",
    limit: 10,
    pagination: {},
    activePage: 1,
    search_clear: true,
    search_select_value: "",
    search_input_value: "",
    success_message: "",
    pageNumber: 1,
    modalIsOpen: false,
    tableData: [],
    reference_id: "",
    userEmail: localStorage.getItem("user_email"),
    terminal_id: localStorage.getItem("user_terminalId"),
  };

  getData = async (
    pageNumber = 1,
    limit = 10,
    filter = false,
    key = "",
    value = ""
  ) => {
    let options = {
      url:
        whiteList_url +
        "?page=" +
        pageNumber +
        "&limit=" +
        limit +
        "&filter=" +
        filter +
        "&key=" +
        key +
        "&value=" +
        value,
    };

    this.setState({ loading: true });

    let api_response = await ApiRequest.get(options);
    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        whiteList: api_response.data.results,
        loading: false,
        error: false,
        pagination: api_response.data.pagination,
        message: api_response.message,
      });
    }

    let referenceNumber = { url: referenceNumber_url };
    const referenceNum = await ApiRequest.get(referenceNumber);
    if (referenceNum.error === false) {
      this.setState({
        reference_id: referenceNum.data.reference_number,
      });
    } else {
      this.setState({
        reference_id: "",
      });
    }
  };

  async componentDidMount() {
    await this.getData();
  }

  openModal = async (data) => {
    this.setState({ tableData: data });
    this.setState({ modalIsOpen: true });
  };

  closeModal = async () => {
    this.setState({ modalIsOpen: false });
  };

  handlePageChange = async (pageNumber) => {
    this.setState({
      pageNumber: pageNumber,
    });
    await this.getData(
      pageNumber,
      this.state.limit,
      this.state.filter,
      this.state.search_select_value,
      this.state.search_input_value
    );
  };
  handleLimitChange = async (event) => {
    this.setState({ limit: event.target.value });
    await this.getData(1, event.target.value);
  };
  handleSearchChange = async (event) => {
    this.setState({
      search_clear: false,
      search_select_value: event.target.value,
      filter: true,
    });
    await this.getData(
      1,
      this.state.limit,
      true,
      event.target.value,
      this.state.search_input_value
    );
  };
  handleSearchValueChange = async (event) => {
    this.setState({
      search_clear: false,
      search_input_value: event.target.value,
      filter: true,
    });
    await this.getData(
      1,
      this.state.limit,
      true,
      this.state.search_select_value,
      event.target.value
    );
  };
  handleSearchClear = async () => {
    this.setState({
      search_clear: true,
      search_input_value: "",
      search_select_value: "",
      filter: false,
    });
    await this.getData(1, this.state.limit, false);
  };
  assignStationChange = async (event) => {
    this.setState({ value: event.target.value });
  };
  assignStationSubmit = async (event) => {
    event.preventDefault();
  };
  onClickHandler = async (event) => {
    const data = new FormData();
    data.append("file", event.target.files[0]);
    this.setState({ loading: true });
    try {
      let rsp = await axios.post(postWhiteList_url, data, {
        headers: { "X-Access-Token": localStorage.getItem("user_token") },
      });
      if (rsp.status === 200) {
        this.setState({
          success: true,
          loading: false,
          success_message: "Card successfully uploaded",
        });
        setTimeout(
          function () {
            this.setState({ success: false });
          }.bind(this),
          5000
        );
        this.openModal(rsp.data.data);
      } else {
        let message = rsp.data.message;
        this.setState({
          error: true,
          loading: false,
          error_message: message.param_missing
            ? message.param_missing + " is required"
            : message,
        });
      }
    } catch (e) {
      let message = "Error Uploading file";
      this.setState({
        error: true,
        loading: false,
        message: message,
      });
    }
  };
  rescue_value(value) {
    return value === undefined ? "-" : value;
  }

  error_status_value(value) {
    return value === true ? "true" : "false";
  }

  blankCard = async (val, event) => {
    confirmAlert({
      title: "Blank Card Sale",
      message: "Are you sure you want to do this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.confirmBlankCard(val),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  confirmBlankCard = async (val) => {
    const formData = new FormData();
    let error = false;

    if (!this.state.reference_id) {
      error = true;
      this.setState({
        error: true,
        error_message: "Reference ID Not Available",
      });
    }

    if (error) {
      return;
    }

    formData.append("method_name", "card_purchase");
    formData.append("transaction_id", new Date().getTime());
    formData.append("card_number", val);
    formData.append("request_from", API.request_from);
    formData.append("reference_id", this.state.reference_id);
    formData.append("userEmail", this.state.userEmail);
    formData.append("terminal_id", this.state.terminal_id);

    this.setState({ loading: true });
    let headers = {
      store_code: API.storeCode,
      store_token: API.storeToken,
      "X-Access-Token": localStorage.getItem("user_token"),
    };
    await axios
      .post(cardOperation_url, formData, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          this.handlePageChange(this.state.pageNumber);
          this.setState({
            success: true,
            loading: false,
            success_message: response.data.message,
          });
        }
        setTimeout(
          function () {
            this.setState({ success: false });
          }.bind(this),
          10000
        );
      })
      .catch((error) => {
        let message = error.response.data.message;
        this.setState({
          error: true,
          loading: false,
          error_message: message.param_missing
            ? message.param_missing + " is required"
            : message,
        });
      });
  };

  render() {
    const {
      loading,
      error,
      message,
      pagination,
      limit,
      success,
      whiteList,
      search_select_value,
      search_input_value,
      success_message,
      tableData,
    } = this.state;

    return (
      <div>
        <Content title="Basma White List " browserTitle="Basma White List">
          <LoadingAjax />
          <Row>
            {error && (
              <div
                class="alert alert-danger alert-dismissible"
                style={{ marginRight: "15px", marginLeft: "15px" }}
              >
                <button
                  type="button"
                  class="close"
                  data-dismiss="alert"
                  aria-hidden="true"
                >
                  ×
                </button>
                {message}
              </div>
            )}
            {success && (
              <div
                class="alert alert-success alert-dismissible"
                style={{ marginRight: "15px", marginLeft: "15px" }}
              >
                <button
                  type="button"
                  class="close"
                  data-dismiss="alert"
                  aria-hidden="true"
                >
                  ×
                </button>
                {success_message}
              </div>
            )}
            <Col xs={12}>
              <div className="box box-primary">
                <div className="box-header with-border box-table-cus">
                  <h3 className="box-title">White List Cards</h3>
                  <div className="box-tools">
                    <a
                      className="btn btn-sm btn-primary mr-5"
                      href="Sample-Basma-New.xlsx"
                    >
                      Sample CSV
                    </a>
                    {ApiRequest.can_access("basma-white-list", "can_add") && (
                      <button
                        className="btn btn-sm btn-primary mr-5"
                        onClick={this.props.viewWhiteList.bind(null, {}, "add")}
                      >
                        Add New
                      </button>
                    )}
		   {ApiRequest.can_access(
                      "bulk-blank-card-activation-form",
                      "can_view"
                    )  && ApiRequest.can_access("bulk-blank-card-activation-form", "can_view") && (
		    <a
                      class="btn btn-sm btn-primary mr-5"
                      href={"/bulk_blank_card_activation_form/"}
                    >
                      Bulk Blank Card Activation
                    </a>
		    )}
		    {ApiRequest.can_access(
                      "bulk-gift-card-activation-form",
                      "can_view"
                    )  && ApiRequest.can_access("bulk-blank-card-activation-form", "can_view") && (
                    <a
                      class="btn btn-sm btn-primary mr-5"
                      href={"/bulk_gift_card_activation_form/"}
                    >
                      Bulk Gift Card Activation
                    </a>
		    )}
                    <input
                      id="uploadList"
                      type="file"
                      name="file"
                      ref={(ref) => (this.uploadList = ref)}
                      onChange={this.onClickHandler}
                      style={{ display: "none" }}
                      accept=".xlsx, .csv, .xls"
                    />
                    {ApiRequest.can_access(
                      "basma-white-list",
                      "can_upload"
                    ) && (
                      <button
                        type="button"
                        className="btn btn-sm btn-primary"
                        onClick={(e) => this.uploadList.click()}
                        // onClick={this.openModal}
                      >
                        {"Upload"}
                      </button>
                    )}
                  </div>
                </div>

                <div className="box-body">
                  <div>
                    <div className="tableHeader">
                      <div className="showEntries">
                        Show{" "}
                        <select
                          value={limit}
                          className="form-control input-sm mr-5 ml-5"
                          onChange={this.handleLimitChange}
                        >
                          <option value={10}>10</option>
                          <option value={25}>25</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>{" "}
                        entries
                      </div>
                      {
                        <div className="cusSearch">
                          Search{" "}
                          <select
                            onChange={this.handleSearchChange}
                            value={search_select_value}
                            className="form-control input-sm mr-5 ml-5"
                          >
                            <option value="">Select Search by </option>
                            <option value={"card_number"}>
                              Card Serial Number
                            </option>
                            <option value={"serial_number"}>Card Number</option>
                            <option value={"store_code"}>Store Code</option>
                          </select>
                          <input
                            type="search"
                            class="form-control input-sm mr-5 ml-5"
                            value={search_input_value}
                            onChange={this.handleSearchValueChange}
                            placeholder=""
                          />
                          <button
                            className="btn btn-primary cusSearchBtn"
                            onClick={this.handleSearchClear}
                          >
                            Clear
                          </button>
                        </div>
                      }
                    </div>
                    <div className="table-responsive">
                      {loading ? (
                        <LoadingSpinner />
                      ) : (
                        <table className="table no-margin">
                          <thead>
                            <tr>
                              <th>ID</th>
                              <th>Card Serial Number</th>
                              <th>Card Number</th>
                              <th>Store Name</th>
                              <th>Store Code</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {whiteList.length > 0 ? (
                              whiteList.map((data) => {
                                return (
                                  <tr key={data.id}>
                                    <td>{this.rescue_value(data.id)}</td>
                                    <td>
                                      {this.rescue_value(data.card_number)}
                                    </td>
                                    <td>
                                      {this.rescue_value(data.serial_number)}
                                    </td>
                                    <td>
                                      {this.rescue_value(data.store_name)}
                                    </td>
                                    <td>
                                      {this.rescue_value(data.store_code)}
                                    </td>

                                    <td>
                                      {ApiRequest.can_access(
                                        "basma-white-list",
                                        "can_view_single"
                                      ) && (
                                        <a
                                          className="btn btn-primary btn-xs mr-5"
                                          href={"/basma_white_list/" + data.id}
                                        >
                                          {" "}
                                          <i className="fa fa-eye" />
                                          {"    "}
                                        </a>
                                      )}
                                      {ApiRequest.can_access(
                                        "basma-white-list",
                                        "can_assign_store"
                                      ) && (
                                        <a
                                          className="btn btn-primary btn-xs  mr-5"
                                          href={"/assign_store/" + data.id}
                                        >
                                          {"Assign Store "}
                                        </a>
                                      )}
                                      {ApiRequest.can_access(
                                        "basma-white-list",
                                        "can_activate"
                                      ) && (
                                        <a
                                          className="btn btn-primary btn-xs mr-5"
                                          href={"/active_card/" + data.id}
                                        >
                                          {"Activate "}
                                        </a>
                                      )}

                                      {ApiRequest.can_access(
                                        "basma-white-list",
                                        "can_sell_empty_card"
                                      ) && (
                                        <button
                                          className="btn btn-primary btn-xs"
                                          onClick={this.blankCard.bind(
                                            null,
                                            data.card_number
                                          )}
                                        >
                                          {"Blank Card "}
                                        </button>
                                      )}
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <td colSpan="5" className="noRecord">
                                No Record Found
                              </td>
                            )}
                          </tbody>
                        </table>
                      )}
                    </div>
                    {pagination.size > 0 && (
                      <div className="tableFooter">
                        <div>
                          <div class="dataTables_info">
                            Showing {(pagination.currentPage - 1) * limit + 1}{" "}
                            to {pagination.currentPage * limit} of{" "}
                            {pagination.size} entries
                          </div>
                        </div>
                        <div style={{ textAlign: "right" }}>
                          <Pagination
                            prevPageText={"<"}
                            nextPageText={">"}
                            activePage={pagination.currentPage}
                            itemsCountPerPage={pagination.limit}
                            totalItemsCount={pagination.size}
                            pageRangeDisplayed={5}
                            onChange={this.handlePageChange}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Content>
        <div className="modal fade" id="modal-assignStation">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title">Assign Store</h4>
              </div>
              <form onSubmit={this.assignStationSubmit}>
                <div className="modal-body">
                  <div className="form-group">
                    <label>Topup Balance</label>
                    <select
                      className="form-control"
                      value={this.state.value}
                      onChange={this.assignStationChange}
                    >
                      <option value="optionOne">Option One</option>
                      <option value="optionTwo">Option Two</option>
                      <option value="optionThree">Option Three</option>
                    </select>
                  </div>
                </div>
                <div className="modal-footer">
                  <input
                    className="btn btn-primary"
                    type="submit"
                    value="Submit"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>

        <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div className="box-header with-border">
            <h2 className="box-title">Upload Status</h2>
            <span className="pull-right">
              <button
                className="btn btn-primary btn-xs mr-5"
                onClick={this.closeModal}
              >
                close
              </button>
            </span>
          </div>

          <div className="table-responsive">
            <table className="table no-margin">
              <thead>
                <th>Card Number</th>
                <th>Serial Number</th>
                <th>Store Code</th>
                <th>Status</th>
                <th>Message</th>
              </thead>
              <tbody>
                {this.state.tableData.map((item) => (
                  <tr>
                    <td>{item.data.card_number}</td>
                    <td>{item.data.serial_number}</td>
                    <td>{item.data.store_code}</td>
                    <td>{this.error_status_value(item.error)}</td>
                    <td>{item.message}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Modal>
      </div>
    );
  }
}
