import React, { useRef } from "react";
import { Content } from "adminlte-2-react";
//import axios from "axios";
import API from "../../constants";
import ApiRequest from "../../ApiRequest";
import LoadingAjax from "../../components/Loading";
//import LoadingSpinner from "../../components/LoadingSpinner";
import DatePicker from "react-datepicker";
import $ from "jquery";
import Select from "react-select";
import moment from "moment";

const cash_back_form_url = API.apiEndpoint + API.CashBackPromotions;
const tierTargetList_url = API.apiEndpoint + API.tierTarget;

const cardTransactionsTopupMethods_url =
  API.apiEndpoint + API.cardTransactionsTopupMethods;

//const schedule_spin_win_url = API.apiEndpoint + API.scheduleSpinAndWin;

export default class CashBackPromotionForm extends React.Component {
  selectRefTier = null;
  selectRefProduct = null;
  selectRefTopMethod = null;

  clearValue = () => {
    this.selectRefTier.select.clearValue();
    this.selectRefProduct.select.clearValue();
  };

  clearTopMethodValue = () => {
    this.selectRefTopMethod.select.clearValue();
  };

  state = {
    page: {},
    action: "add",
    cash_back_promotion_list: [],
    gameId: "",
    error: false,
    message: "",
    loading: true,
    success: false,
    success_message: "",
    start: "",
    end: "",
    tier_target: "percentage",
    stores: [],

    selectedOption: null,
    isRpsChecked: true,
    isAppChecked: true,
    isTMChecked: false,
    disabled: false,
    topupChecked: false,
    event_lock: true,

    // allowed_payment_methods: JSON.parse(
    //   localStorage.getItem("allowed_payment_methods")
    // ),
  };

  async componentDidMount() {
    let options = { url: tierTargetList_url };
    let api_response = await ApiRequest.get(options);

    let optionsTopupMethods = { url: cardTransactionsTopupMethods_url };
    let topupMethod_response = await ApiRequest.get(optionsTopupMethods);

    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      let stores = [];
      api_response.data.map((store) => {
        stores.push({ value: store.CardTypeName, label: store.CardTypeName });
      });

      let t_method = [];
      topupMethod_response.data.map((methods, i) => {
        t_method.push({
          value: methods,
          label: methods,
        });
      });

      this.setState({
        productsSelected: [],
        topup_method_Selected: [],
        stores: stores,
        t_method: t_method,
        //tier_list: api_response.data,
        loading: false,
        error: false,
        message: api_response.message,
      });
    }
  }
  handleChange = (event) => {
    this.setState({ value: event.target.value });
  };
  promotionTypeChange = (event) => {
    this.setState({ promotionType: event.target.value });
  };
  cashBackPromotionStart = async (date) => {
    this.setState({
      cash_back_promotion_date_from: date,
      enableBtn: true,
    });
  };
  cashBackPromotionEnd = async (date) => {
    this.setState({
      cash_back_promotion_date_to: date,
      enableBtn: true,
    });
  };
  tierTargetChange = (event) => {
    this.setState({ tier_target: event.target.value });
  };
  productsChange = (event) => {
    this.setState({ products: event.target.value });
  };
  cashBackFromValueChange = (event) => {
    this.setState({ cashBackFromValue: event.target.value });
  };
  cashBackToValueChange = (event) => {
    this.setState({ cashBackToValue: event.target.value });
  };
  rewardBudgetValueChange = (event) => {
    this.setState({ rewardBudgetValue: event.target.value });
  };
  cashBackRewardValueChange = (event) => {
    this.setState({ cashBackRewardValue: event.target.value });
  };
  tierNameChange = (event) => {
    this.setState({ tierName: event.target.value });
  };
  handleTierChange = (selectedTierOption) => {
    this.setState({ tierSelected: selectedTierOption });
  };
  onChangeRpsCheck = (event) => {
    const { physical } = this.state;
    this.setState({
      physical: physical,
      enableBtn: true,
      isRpsChecked: !this.state.isRpsChecked,
    });
  };
  onChangeAppCheck = (event) => {
    const { virtual } = this.state;
    this.setState({
      virtual: virtual,
      enableBtn: true,
      isAppChecked: !this.state.isAppChecked,
    });
  };
  handleProductsChange = (selectedProductsOption) => {
    this.setState({ productsSelected: selectedProductsOption });
  };
  topupMethodChange = async (selectedTmethodOption) => {
    this.setState({ topup_method_Selected: selectedTmethodOption });
  };

  onChangeTopupMethodCheck = (event) => {
    const { method_top } = this.state;
    this.setState({
      method_top: method_top,
      enableBtn: true,
      isTMChecked: !this.state.isTMChecked,
    });
    if (event.target.checked) {
      this.setState({
        event_lock: false,
        isTMChecked: true,
        // isAppChecked: !this.state.isAppChecked,
        // isRpsChecked: !this.state.isRpsChecked,
      });
      this.clearValue();
    } else {
      this.setState({
        event_lock: true,
        isTMChecked: false,
      });
      this.clearTopMethodValue();
    }

    if (
      event.target.checked ||
      this.state.isAppChecked ||
      this.state.isRpsChecked
    ) {
      this.setState({
        isAppChecked: false,
        isRpsChecked: false,
      });
    } else {
      this.setState({
        isAppChecked: true,
        isRpsChecked: true,
      });
    }
  };

  // onChangeTopupMethodCheck = (event) => {
  //   this.setState({
  //     enableBtn: true,
  //     isTopupMethodChecked: !this.state.isTMChecked,
  //     isAppChecked: !this.state.isAppChecked,
  //     isRpsChecked: !this.state.isRpsChecked,
  //   });
  //   if (event.target.checked) {
  //     this.setState({
  //       event_lock: false,
  //       topupChecked: true,
  //     });
  //     this.clearValue();
  //   } else {
  //     this.setState({
  //       event_lock: true,
  //       topupChecked: false,
  //     });
  //     this.clearTopMethodValue();
  //   }
  // };

  isTMChecked;

  saveCashBack = async (event) => {
    let error = false;

    if (!this.state.cash_back_promotion_date_from) {
      error = true;
      this.setState({
        error: true,
        message: "Start Date is required",
      });
      return;
    }

    if (!this.state.cash_back_promotion_date_to) {
      error = true;
      this.setState({
        error: true,
        message: "End Date is required",
      });
      return;
    }
    if (!this.state.tierName) {
      error = true;
      this.setState({
        error: true,
        message: "Name value is required",
      });
      return;
    }

    if (
      !this.state.isTMChecked &&
      Array.isArray(this.state.tierSelected) &&
      this.state.tierSelected.length == 0
    ) {
      error = true;
      this.setState({
        error: true,
        message: "Tier is required",
      });
      return;
    }

    if (
      !this.state.isTMChecked &&
      Array.isArray(this.state.productsSelected) &&
      this.state.productsSelected.length == 0
    ) {
      error = true;
      this.setState({
        error: true,
        message: "Products is required",
      });
      return;
    }
    if (
      this.state.isTMChecked &&
      Array.isArray(this.state.topup_method_Selected) &&
      this.state.topup_method_Selected.length == 0
    ) {
      error = true;
      this.setState({
        error: true,
        message: "Topup Method is required",
      });
      return;
    }
    if (!this.state.cashBackFromValue) {
      error = true;
      this.setState({
        error: true,
        message: "Topup value from is required",
      });
      return;
    }
    if (!this.state.cashBackToValue) {
      error = true;
      this.setState({
        error: true,
        message: "Topup value to is required",
      });
      return;
    }
    if (!this.state.cashBackRewardValue) {
      error = true;
      this.setState({
        error: true,
        message: "Reward Value is required",
      });
      return;
    }
    if (!this.state.rewardBudgetValue) {
      error = true;
      this.setState({
        error: true,
        message: "Reward Budget is required",
      });
      return;
    }

    if (this.state.rewardBudgetValue <= 0) {
      error = true;
      this.setState({
        error: true,
        message: "Reward Budget must be greater than 0",
      });
      return;
    }

    var productsPost = [];
    this.state.productsSelected &&
      this.state.productsSelected.map((productSelect) => {
        productsPost.push(productSelect.value);
      });

    var tierPost = [];
    this.state.tierSelected &&
      this.state.tierSelected.map((tierSelect) => {
        tierPost.push(tierSelect.value);
      });

    var topMethod = [];
    this.state.topup_method_Selected &&
      this.state.topup_method_Selected.map((topMethods) => {
        topMethod.push(topMethods.value);
      });

    var moment = require("moment");

    const formData = new FormData();
    formData.append(
      "from",
      moment
        .utc(this.state.cash_back_promotion_date_from)
        // .add(1, "days")
        // .endOf("day")
        .tz("Asia/Muscat")
        .valueOf()
    );
    formData.append(
      "to",
      moment
        .utc(this.state.cash_back_promotion_date_to)
        // .add(1, "days")
        // .endOf("day")
        .tz("Asia/Muscat")
        .valueOf()
    );

    formData.append("name", this.state.tierName);
    formData.append("reward_type", this.state.tier_target);
    formData.append("reward_value", this.state.cashBackRewardValue);
    formData.append("products", JSON.stringify(productsPost));
    formData.append("tiers", JSON.stringify(tierPost));
    formData.append("budget", this.state.rewardBudgetValue);
    formData.append("topup_method", JSON.stringify(topMethod));
    //formData.append("topupMethodChecked", this.state.isTopupMethodChecked);

    formData.append(
      "transaction_value_range_from",
      this.state.cashBackFromValue
    );
    formData.append("transaction_value_range_to", this.state.cashBackToValue);

    var transaction_type_data = [];

    if (this.state.isRpsChecked === true) {
      transaction_type_data.push("rps");
    }
    if (this.state.isAppChecked === true) {
      transaction_type_data.push("app");
    }
    if (this.state.isTMChecked === true) {
      transaction_type_data.push("topup");
    }

    formData.append("transaction_type", JSON.stringify(transaction_type_data));

    let api_response = {};
    let options = { url: cash_back_form_url, form_data: formData };
    api_response = await ApiRequest.post(options);

    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        success: true,
        loading: false,
        success_message: api_response.message,
      });
      setTimeout(
        function () {
          this.setState({ success: false });
          window.location = "/cash_back_promotion";
        }.bind(this),
        10000
      );
    }
  };
  backUser = () => {
    window.location = "/cash_back_promotion";
  };

  topupChecked(val) {
    return val === true ? true : false;
  }

  render() {
    let {
      error,
      message,
      success,
      success_message,
      stores,
      selectedTierOption,
      selectedProductsOption,
      selectedTmethodOption,
      t_method,
    } = this.state;

    var products = [
      { value: "MOGAS 91", label: "MOGAS 91" },
      { value: "MOGAS 95", label: "MOGAS 95" },
      { value: "Diesel", label: "Diesel" },
      { value: "MOGAS 98", label: "MOGAS 98" },
    ];

    return (
      <Content title="Cash Back Promotion" browserTitle="Cash Back Promotion">
        <LoadingAjax />
        <div className="row">
          {error && (
            <div
              className="alert alert-danger alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {message}
            </div>
          )}
          {success && (
            <div
              className="alert alert-success alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {success_message}
            </div>
          )}
          <div className="col-md-12">
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">Cash Back Promotion Form </h3>
              </div>

              <div className="box-body">
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="loyaltyGameId">Promotion Type</label>
                    <select
                      className="form-control"
                      id="loyaltyGameId"
                      value={this.state.promotionType}
                      onChange={this.promotionTypeChange}
                    >
                      <option> Cash Back Promotion </option>
                    </select>
                  </div>
                </div>
                <div className="form-group rangeFilter col-sm-4">
                  <label>Promotion Date</label>
                  <DatePicker
                    showTimeSelect
                    dateFormat="Pp"
                    // dateFormat="MM/dd/yyyy"
                    selected={this.state.cash_back_promotion_date_from}
                    selectsStart
                    cash_back_promotion_date_from={
                      this.state.cash_back_promotion_date_from
                    }
                    cash_back_promotion_date_to={
                      this.state.cash_back_promotion_date_to
                    }
                    onChange={this.cashBackPromotionStart}
                    minDate={moment().toDate()}
                    placeholderText="From"
                  />
                  <DatePicker
                    showTimeSelect
                    dateFormat="Pp"
                    //dateFormat="MM/dd/yyyy"
                    selected={this.state.cash_back_promotion_date_to}
                    selectsEnd
                    cash_back_promotion_date_from={
                      this.state.cash_back_promotion_date_from
                    }
                    cash_back_promotion_date_to={
                      this.state.cash_back_promotion_date_to
                    }
                    onChange={this.cashBackPromotionEnd}
                    minDate={this.state.cash_back_promotion_date_from}
                    placeholderText="To"
                  />
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="tierName">Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Name"
                      value={this.state.tierName}
                      onChange={this.tierNameChange}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="tierTarget">Topup value </label>
                    <div className="form-group-custom">
                      <div className="form-control form-group-custom mr-5">
                        <input
                          type="number"
                          min="0"
                          className="bdr0 cusInput "
                          placeholder="From"
                          value={this.state.cashBackFromValue}
                          onChange={this.cashBackFromValueChange}
                        />
                        <span style={{ color: "#ccc" }}>OMR</span>
                      </div>
                      <div className="form-control form-group-custom ">
                        <input
                          type="number"
                          min="0"
                          className="bdr0 cusInput"
                          placeholder="To"
                          value={this.state.cashBackToValue}
                          onChange={this.cashBackToValueChange}
                        />
                        <span style={{ color: "#ccc" }}>OMR</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="reward">Reward Value </label>
                    <div className="form-group-custom">
                      <select
                        style={{ width: "50%" }}
                        className="form-control mr-5"
                        value={this.state.tier_target}
                        onChange={this.tierTargetChange}
                      >
                        <option value="percentage">Percentage</option>
                        <option value="fixed">Fixed</option>
                      </select>
                      <input
                        type="number"
                        min="0"
                        className="form-control"
                        placeholder="00"
                        value={this.state.cashBackRewardValue}
                        onChange={this.cashBackRewardValueChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="rewardBudget">Reward Budget</label>
                    <input
                      type="number"
                      min="0"
                      className="form-control"
                      placeholder="Reward budget"
                      value={this.state.rewardBudgetValue}
                      onChange={this.rewardBudgetValueChange}
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <label>
                    <input
                      className="ml-5"
                      onChange={this.onChangeTopupMethodCheck}
                      type="checkbox"
                      // checked={method_top}
                      checked={this.state.isTMChecked}
                    />
                    {"Topup"}
                  </label>
                </div>
                <div className="col-md-4">
                  <div className={!this.state.event_lock ? "event_lock" : ""}>
                    <label>
                      <input
                        className="ml-5"
                        onChange={this.onChangeRpsCheck}
                        type="checkbox"
                        id="physicalId"
                        // checked={physical}
                        checked={this.state.isRpsChecked}
                      />
                      {"Rps"}
                    </label>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className={!this.state.event_lock ? "event_lock" : ""}>
                    <label>
                      <input
                        className="ml-5"
                        onChange={this.onChangeAppCheck}
                        type="checkbox"
                        id="surveySponsored"
                        // checked={virtual}
                        checked={this.state.isAppChecked}
                      />
                      {"App"}
                    </label>
                  </div>
                </div>
                <div className="col-md-4">
                  <div
                    className={
                      this.state.event_lock
                        ? "form-group event_lock"
                        : "form-group"
                    }
                  >
                    <label htmlFor="tierTarget">Topup Method</label>
                    <Select
                      ref={(ref) => {
                        this.selectRefTopMethod = ref;
                      }}
                      value={selectedTmethodOption}
                      onChange={this.topupMethodChange}
                      options={t_method}
                      isMulti
                      isSearchable
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div
                    className={
                      !this.state.event_lock
                        ? "form-group event_lock"
                        : "form-group"
                    }
                  >
                    <label htmlFor="tierTarget">Tier Target</label>
                    <Select
                      ref={(ref) => {
                        this.selectRefTier = ref;
                      }}
                      value={selectedTierOption}
                      onChange={this.handleTierChange}
                      options={stores}
                      isMulti
                      isSearchable
                      isClearable={true}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div
                    className={
                      !this.state.event_lock
                        ? "form-group event_lock"
                        : "form-group"
                    }
                  >
                    <label htmlFor="products">Products</label>
                    <Select
                      ref={(ref) => {
                        this.selectRefProduct = ref;
                      }}
                      value={selectedProductsOption}
                      onChange={this.handleProductsChange}
                      options={products}
                      isMulti
                      isSearchable
                      isClearable={true}
                    />
                  </div>
                </div>
              </div>
              <div className="box-footer clearfix no-border">
                <button
                  onClick={this.saveCashBack}
                  className="btn btn-primary pull-right "
                >
                  Submit
                </button>
                <button
                  onClick={this.backUser}
                  className="btn btn-default pull-right mr-10"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Content>
    );
  }
}
