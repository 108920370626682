import React from "react";
import { Content, Row, Col } from "adminlte-2-react";
import axios from "axios";
import API from "./../../constants";
import LoadingSpinner from "../../components/LoadingSpinner";
import ApiRequest from "./../../ApiRequest";

const appUser_url = API.apiEndpoint + API.appView;
const changeStatus_url = API.apiEndpoint + API.changeStatus;
const editProfile_url = API.apiEndpoint + "/app-user-detail/";
const businessUserRequestsAccept_url = API.apiEndpoint + API.businessUserRequests_accept_url;
const demoteBusinessUser_url = API.apiEndpoint + API.demote_businessUser_url;

export default class AppView extends React.Component {
  state = {
    appview: {},
    cards: [],
    loading: true,
    valueOpt: ""
  };

  async componentDidMount() {
    const response = await axios.get(
      appUser_url + "/" + this.props.match.params.id
    );
    if (response.status === 200) {
      const data = response.data.data;
      this.setState({
        appview: data,
        cards: data.cards,
        loading: false
      });
    }
  }

  rescue_value(value) {
    return value === 0 ? "Inactive" : "Active";
  }

  handleChange = e => this.setState({ valueOpt: e.target.value });

  onClickHandler = async event => {
    const data = new FormData();
    data.append("user_id", this.state.appview.id);
    data.append("status", this.state.valueOpt);
    let changeStatus = await axios.post(changeStatus_url, data, {});
    if (changeStatus.status === 200) {
      this.setState({ loading: false });
    }
  };

  toggleNFC = async (user_id, value) => {
    let headers = {
      store_code: API.storeCode,
      store_token: API.storeToken,
      "X-Access-Token": localStorage.getItem("user_token"),
    };
    await axios
      .post(editProfile_url + user_id, { hasNfcFeature: value }, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            success: true,
            loading: false,
            success_message: response.data.message,
            appview: { ...this.state.appview, hasNfcFeature: value }
          });
        }
      })
  }

  makeBusinessUser = async (user_id, oomcoId) => {
    try {
      let headers = {
        "X-Access-Token": localStorage.getItem("user_token"),
      };
      let params = {
        acceptedBy: localStorage.getItem("user_id"),
        oomcoId: oomcoId,
      }
      await axios
        .post(businessUserRequestsAccept_url + '/' + user_id, params, { headers: headers })
        .then((response) => {
          if (response.status === 200) {
            this.setState({
              success: true,
              loading: false,
              success_message: response.data.message,
              appview: { ...this.state.appview, isBusinessUser: true }
            });
          } else {
            this.setState({
              success: false,
              loading: false,
              message: response.data.message,
              appview: { ...this.state.appview }
            });
          }
        })
    } catch (e) {
      console.log(e)
    }
  }

  demoteBusinessUser = async (user_id) => {
    try {
      let headers = {
        "X-Access-Token": localStorage.getItem("user_token"),
      };
      let params = {
        id: user_id
      }
      await axios
        .post(demoteBusinessUser_url, params, { headers: headers })
        .then((response) => {
          if (response.status === 200) {
            this.setState({
              success: true,
              loading: false,
              success_message: response.data.message,
              appview: { ...this.state.appview, isBusinessUser: false }
            });
          } else {
            this.setState({
              success: false,
              loading: false,
              message: response.data.message,
              appview: { ...this.state.appview }
            });
          }
        })
    } catch (e) {
      console.log(e)
    }
  }

  rescue_gender(value) {
    let val = parseInt(value);
    switch (val) {
      case 0:
        return "Un specified";
      case 1:
        return "Male";
      case 2:
        return "Female";
      default:
        return "-";
    }
  }

  render() {
    let { appview, cards, loading } = this.state;

    return (
      <Content title={"User Detail"} browserTitle="User Detail">
        {loading ? (
          <LoadingSpinner />
        ) : (
          <Row>
            <Col xs={6}>
              <div className="box box-primary">
                <div className="box-header with-border">
                  <h3 className="box-title">Basic Information</h3>
                  <span className="pull-right">
                    <button
                      className="btn btn-primary btn-xs  mr-5"
                      onClick={this.toggleNFC.bind(null, appview.id, !appview.hasNfcFeature)}
                    >
                      {appview.hasNfcFeature ? "Disable NFC" : "Enable NFC"}
                    </button>
                  </span>
                  {
                    !appview.isBusinessUser && (
                      <span className="pull-right">
                        <button
                          className="btn btn-primary btn-xs mr-5"
                          onClick={this.makeBusinessUser.bind(null, appview.id, appview.oomcoId)}
                        >
                          {"Make Business User"}
                        </button>
                      </span>
                    )
                  }
                  {
                    appview.isBusinessUser && (
                      <span className="pull-right">
                        <button
                          className="btn btn-primary btn-xs mr-5"
                          onClick={this.demoteBusinessUser.bind(null, appview.user_id)}
                        >
                          {"Demote Business User"}
                        </button>
                      </span>
                    )
                  }
                </div>
                <div className="box-body">
                  <ul className="list-group list-group-unbordered">
                    <li className="list-group-item">
                      <b>ID</b>{" "}
                      <span className="pull-right">{appview.id} </span>
                    </li>
                    <li className="list-group-item">
                      <b>Name</b>{" "}
                      <span className="pull-right">{appview.name} </span>
                    </li>
                    <li className="list-group-item">
                      <b>Email</b>{" "}
                      <span className="pull-right">
                        {appview.email_address ? appview.email_address : "-"}
                      </span>
                    </li>
                    <li className="list-group-item">
                      <b>Date of Birth</b>{" "}
                      <span className="pull-right">
                        {appview.dob
                          ? ApiRequest.date_format_utc(appview.dob)
                          : "-"}
                      </span>
                    </li>
                    <li className="list-group-item">
                      <b>Gender</b>{" "}
                      <span className="pull-right">
                        {this.rescue_gender(appview.gender)}
                      </span>
                    </li>
                    <li className="list-group-item">
                      <b>Region</b>{" "}
                      <span className="pull-right">
                        {appview.city ? appview.city.name.en : "-"}
                      </span>
                    </li>
                    <li className="list-group-item">
                      <b>Join Date</b>{" "}
                      <span className="pull-right">
                        {appview.createdAt
                          ? ApiRequest.date_format_utc(appview.createdAt)
                          : "-"}
                      </span>
                    </li>
                    <li className="list-group-item">
                      <b>OOMCO ID</b>{" "}
                      <span className="pull-right">{appview.oomcoId} </span>
                    </li>
                    {appview.nss_number ? (
                      <li className="list-group-item">
                        <b>NSS Card Number</b>{" "}
                        <span className="pull-right">
                          {appview.nss_number}{" "}
                        </span>
                      </li>
                    ) : (
                      ""
                    )}

                    <li className="list-group-item">
                      <b>Phone</b>{" "}
                      <span className="pull-right">{appview.phone_format}</span>
                    </li>
                    {appview.civil_number ? (
                      <li className="list-group-item">
                        <b>Civil Number</b>{" "}
                        <span className="pull-right">
                          {appview.civil_number}{" "}
                        </span>
                      </li>
                    ) : (
                      ""
                    )}

                    <li className="list-group-item">
                      <b>Status</b>{" "}
                      <span className="pull-right">
                        {/*<select onChange={this.handleChange}>
                          <option value="active">Active</option>
                          <option value="deactive">Deactive</option>
                        </select>*/}
                        {this.rescue_value(appview.status)}
                      </span>
                    </li>
                    <li className="list-group-item">
                      <b>Has NFC feature</b>{" "}
                      <span className="pull-right">
                        {" "}
                        {appview.hasNfcFeature ? "Yes" : "No"}{" "}
                      </span>
                    </li>
                    <li className="list-group-item">
                      <b>Cards Count</b>{" "}
                      <span className="pull-right">
                        {" "}
                        {appview.cards_count}{" "}
                      </span>
                    </li>
                    <li className="list-group-item">
                      <b>Potential Business User</b>{" "}
                      <span className="pull-right">
                        {appview.isPotentialBusinessUser ? "Yes" : "No"}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
            {cards.length > 0 && (
              <Col xs={6}>
                <div className="box box-primary">
                  <div className="box-header with-border">
                    <h3 className="box-title">Card Information</h3>
                  </div>
                  <div className="box-body">
                    <table className="table no-margin">
                      <thead>
                        <tr>
                          <th>Card Serial Number</th>
                          <th>Status</th>
                          <th>Car Number</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {cards.map(card => (
                          <tr>
                            {" "}
                            <td>{card.card_number}</td>
                            <td>{this.rescue_value(card.activate)}</td>
                            <td>{card.car_plate}</td>
                            <td>
                              <a
                                class="btn btn-primary btn-xs mr-5"
                                href={"/card/" + card.id}
                              >
                                {" "}
                                <i class="fa fa-eye" />
                                {"    "}
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </Col>
            )}
          </Row>
        )}
      </Content>
    );
  }
}
