const API = {
  apiEndpoint: process.env.REACT_APP_API_ENDPOINT ?? "http://localhost",
  apiAuthorization: "kRhbuUB2Q_%qf/Mn1X1^^ivu%S0+/L(MQ_X9NZ/(i.(4WhnP+7fc:A!", // apiAuthorization
  stationLocator: "/get-stations",
  get_all_cms: "/cms/all",
  cms: "/cms",
  stationReview: "/station-rating",
  contactReview: "/contactus",
  complaintReview: "/complaint",
  complaintType: "/complaint-type",
  newsReview: "/news",
  safety: "/safetytip",
  fuel: "/fuelprice",
  login: "/admin/login",
  GoogleMapKey: "AIzaSyD19N77w1XiX130aR8AgTvStCpP2vOXceE",
  MapCenter: [23.614328, 58.545284],
  LocatorStation: "https://api.myjson.com/bins/d9evv",
  adminUser: "/BusinessUser",
  adminUserView: "/BusinessUser",
  appUser: "/get-app-users",
  basmaCards: "/card-detail",
  roles: "https://api.myjson.com/bins/h006f",
  role: "/role",
  whiteList: "/get_whitelist-cards",
  addCard: "/add-card",
  getCard: "/card-detail",
  icon: "/icons/",
  configuration: "/configuration",
  filters: "/get-filters",
  dashboard: "/dashboard",
  sendTip: "/send-tip",
  store: "/store",
  assignStore: "/assign-store",
  topupCard: "/assign-store",
  cardReplace: "/card-operation",
  cardOperation: "/card-operation",
  storeCode: localStorage.getItem("store_code") ? localStorage.getItem("store_code") : "12345",
  storeToken: localStorage.getItem("store_token") ? localStorage.getItem("store_token") : "7810241606708",
  oldCard: "/old-cards",
  appView: "/get-user-detail",
  cardTransaction: "/cardtranscationlog",
  rpsTransaction: "/rpstransactions",
  sfsTransactions: "/sfstransactions",
  uploadWhiteList: "/upload-cards",
  changeStatus: "/get-user-detail",
  cardFilter: "/card-detail",
  getModule: "/ModulePermissions",
  city: "/city",
  reportInventory: "/reports/cards/inventory",
  reportSales: "/reports/cards/sales",
  reportCardTransactions: "/reports/cards/transaction",
  cardTransactionsType: "/card-transaction-types",
  cardTransactionsTopupMethods: "/card-transaction-topup-methods",
  reportUsers: "/reports/user",
  getFuelPrice: "/get-fuel-price",
  reportRefueling: "/reports/refueling",
  reportStoreTrans: "/reports/stores/transaction",
  storeUpload: "/upload-store ",
  storeTransType: "/store-transaction-operation-sources",
  storeTypeWithAmount: "/store-transaction-types-with-amount",
  pushNotification: "/send-push-notification",
  pushNotificationAccessToken: "y^g#$If3*#N'9EPV)/^*^V$%P|>Dnc",
  carplateTypes: "/carplate_types",
  brands: "/brands",
  request_from: "MobileApp",
  analyticsReport: "/reports/cards/sales/analytics",
  referenceNumber: "/getReferenceNumberForTransaction",
  epaymentTransaction: "/reports/epayment-transaction",
  nfcPinSignatureList: "/reports/nfc-pin-signature-list",
  badgeManagement: "/badgemanagement",
  fuelReward: "/FuelReward",
  requestFrom: "/card-transaction-request-from",
  analyticsCardInventory: "/reports/cards/inventory/analytics",
  analyticsCardTransaction: "/reports/cards/transaction/analytics",
  analyticsUser: "/reports/user/analytics",
  analyticsRefueling: "/reports/refueling/analytics",
  questionBank: "/questions", // Create Question
  quiz: "/quiz", // Create Quiz
  quizTrigger: "/quiztrigger",
  survey: "/survey",
  loyaltyQuizList: "/getLoyaltyQuizList",
  spinAndWin: "/spinandwin",
  spinAndWinList: "/getLoyaltySpinAndWin",
  scheduleQuiz: "/validate/schedulling/quiz",
  scheduleSpinAndWin: "/validate/schedulling/spinandwin",
  scheduleSurvey: "/validate/schedulling/survey",
  quizReport: "/reports/quiz",
  surveyReport: "/reports/survey",
  spinandwinReport: "/reports/spinandwin",
  tierTarget: "/getTiers",
  CashBackPromotions: "/CashBackPromotions",
  TopupRewardPromotions: "/TopupRewardPromotions",
  TopupRewardPromotions: "/TopupRewardPromotions",
  FuelVoucherPromotions: "/FuelVoucherPromotions",
  PromoCode: "/PromoCode",
  PromoCodeGen: "/promocode/generate",
  CashBackReport: "/reports/cashbackpromotions",
  TopUpReport: "/reports/topuppromotions",
  PromoCodeReport: "/reports/promocode",
  WinnerSpinAndWin: "/getSpinAndWindDetails",
  Comment: "/comments",
  VirtualCardsReport: "/reports/virtualcards/transaction",
  VirtualCardsAnalytics: "/reports/virtualcards/transaction/analytics",
  InappPromotions: "/InappPromotions",
  UploadFile: "/upload-file",
  storageAccessToken: "3q99onx2jxvpnd2g3q99onx2jxvpnd2h",
  bulkBlankCardSales: "/bulk-blank-card-sales-form",
  uploadCardsRepository: "/upload-cards-repository",
  cardsRepositoryToWishlist: "/cards-repository-to-wishlist",
  bulkGiftCardActivation: "/bulk-gift-card-activation",
  cardsRepositoryList: "/cardsRepository",
  lastGiftCarPlate: "/get-last-gft-car-plate",
  bulkGiftCardActivationList: "/bulkGiftCardActivation",
  specialUser: "/getSpecialUsers",
  storeAdminUsers: "/getStoreAdminUsers",
  badgeManagement: "/badgemanagement",
  campaingBarCode: "/campaignBarcode",
  campaingEdit: "/campaign",
  campaingGet: "/CampaignBarcode",
  uploadbulkbarcode: "/uploadbulkbarcode",
  listingproductbarcode: "/listingproductbarcode",
  exportbarcode: "/exportbarcode",
  addProductBarcode: "/addProductBarcode",
  getCampaign: "/get-campaign",
  campaignbarcodelist: "/campaignbarcodelist",
  updateBadge: "/update-badge",
  findBadge: "/findOnebadge",
  updateBarcode: "/updatebarcode",
  removeBarcode: "/removebarcode",
  loyaltyPartner: "/loyaltyPartner",
  getLoyaltyPartner: "/get-loyalty-partner",
  loyaltyPartnerTransaction: "/loyaltyPartnerTransaction",
  loyaltyPartnerTransactionView: "/get-loyalty-partner-transaction",
  myGarage: "/get-all-vehicles-garage",
  updateGarage: "/update-vehicle",
  myGarageView: "/get-single-vehicle-detail",
  recordInsurances: "/record_insurances",
  surveyexport: "/reports/surveyexport",
  surveysummary: "/reports/surveysummary",
  stampCard: "/stampcard",
  uploadVoucherCodes: "/upload-voucher-codes",
  stampcardDelete: "/stampcard-delete",
  nfctag: "/find-bulk-nfctag",
  softdeletenfctag: "/nfc-softsDelete-recordByAdmin",
  nfctagUpload: "/bulk-upload-nfctag",
  nfctagReport: "/reports/nfc",
  uploadBulkAdminUser: "/business_user/bulkChangePassword",
  uploadAssignStoreMobile: "/business_user/bulkAssignStoreMobile",
  invalidMobileAttempt: "/invalid-attempt-manual",
  completeIncompleteTransaction: "/completeIncompleteTransactions",
  completeThawaniTransaction: "/thawanitransaction/complete",
  verifyThawaniTransaction: "/thawanitransaction/verifyThawani",
  thawaniTransaction: "/thawanitransaction",
  offlineLoyaltyRewards: "/rpstransactions",
  limitHittingRecording: "/limit-hits-log",
  selfServiceNozzles: "/self-service-nozzles",
  onBoardingUrlGenerator : "/onboarding-url-generator",
  loyaltyCardsDistribution: "/loyaltycardsdistribution",
  loyaltyCardsDistributionByStore: "/stations-cards-distribution-by-store",
  loyaltyCardsDistributionByStoreStatistics: "/stations-cards-distribution-by-store-statistics",
  refundThawaniTransaction: "/thawani/refund",
  businessUserRequests: "/businessuserrequests",
  businessUserRequests_change_status_url: "/business-user-requests-change-status",
  businessUserRequests_accept_url: "/business-user-requests-accept",
  demote_businessUser_url: "/business-user-requests-demote",
  retry_3p_transaction_url: "/retry_3p_topups_ussd",
  reconciliation_url: "/reconciliation",
  reconciliation_trx_url: "/reconciliationTransactions",
  businessUserRequestsView: "/get-business-user-request",
};

export default API;
