import React from "react";
import { Content } from "adminlte-2-react";
//import axios from "axios";
import API from "../../constants";
import ApiRequest from "../../ApiRequest";
import LoadingAjax from "../../components/Loading";
//import LoadingSpinner from "../../components/LoadingSpinner";
import DatePicker from "react-datepicker";
import $ from "jquery";
import Select from "react-select";
import moment from "moment";

const edit_cash_back_form_url = API.apiEndpoint + API.CashBackPromotions;
const tierTargetList_url = API.apiEndpoint + API.tierTarget;

const cardTransactionsTopupMethods_url =
  API.apiEndpoint + API.cardTransactionsTopupMethods;

export default class CashBackPromotionEditForm extends React.Component {
  selectRefTier = null;
  selectRefProduct = null;
  selectRefTopMethod = null;

  clearValue = () => {
    this.selectRefTier.select.clearValue();
    this.selectRefProduct.select.clearValue();
  };

  clearTopMethodValue = () => {
    this.selectRefTopMethod.select.clearValue();
  };

  state = {
    topup_promotion_list: [],
    loyaltyGameId: "",
    error: false,
    message: "",
    loading: true,
    success: false,
    success_message: "",
    active: "",
    start: "",
    end: "",
    stores: [],
    t_method: [],
  };

  async componentDidMount() {
    let url = edit_cash_back_form_url + "/" + this.props.match.params.id;
    let options = { url: url };
    let api_response = await ApiRequest.get(options);

    let data = api_response.data;
    let tierTargeOptions = { url: tierTargetList_url };
    let tier_response = await ApiRequest.get(tierTargeOptions);

    let optionsTopupMethods = { url: cardTransactionsTopupMethods_url };
    let topupMethod_response = await ApiRequest.get(optionsTopupMethods);

    if (api_response.error || tier_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      let stores = [];
      tier_response.data.map((store) => {
        stores.push({ value: store.CardTypeName, label: store.CardTypeName });
      });

      let transaction_type_res = {};
      api_response.data.transaction_type.map((RAChecked) => {
        transaction_type_res[RAChecked] = true;
      });

      let productsValue = [];
      api_response.data.products.map((store) => {
        productsValue.push({
          value: store,
          label: store,
        });
      });

      let tierValue = [];
      api_response.data.tiers.map((store) => {
        tierValue.push({
          value: store,
          label: store,
        });
      });

      let t_method_revise = [];
      if(api_response.data.topup_method){
        api_response.data.topup_method.map((method) => {
          t_method_revise.push({
            value: method,
            label: method,
          });
        });
      }

      let t_method = [];
      topupMethod_response.data.map((methods) => {
        t_method.push({
          value: methods,
          label: methods,
        });
      });

      var startDate = new Date(data.from);
      var endDate = new Date(data.to);

      this.setState({
        stores: stores,
        t_method: t_method,
        cash_back_promotion_date_from: startDate,
        cash_back_promotion_date_to: endDate,
        cashBackFromValue: data.transaction_value_range_from,
        cashBackToValue: data.transaction_value_range_to,
        tierName: data.name,
        cashBackRewordValue: data.reward_value,
        rewordBudgetValue: data.budget,
        productsSelected: productsValue,
        //selectedTmethod: t_method_revise,
        tierSelected: tierValue,
        topup_method_Selected: t_method_revise,
        tier_target: data.reward_type,
        loading: false,
        error: false,
        message: api_response.message,
        isRpsChecked: transaction_type_res.rps == true ? true : false,
        isAppChecked: transaction_type_res.app == true ? true : false,
        isTMChecked: transaction_type_res.topup == true ? true : false,
        isChecked:
          data.topupMethodChecked === "undefined"
            ? false
            : data.topupMethodChecked,
      });

      // if (this.state.isRpsChecked == true && this.state.isAppChecked == true) {
      //   this.setState({
      //     event_lock: true,
      //   });
      // }
    }
  }

  handleChange = (event) => {
    this.setState({ value: event.target.value });
  };
  promotionTypeChange = (event) => {
    var option = $("option:selected", event.target).attr("data-name");
    this.setState({ promotionType: event.target.value, gameName: option });
  };
  tierTargetChange = (event) => {
    this.setState({ tier_target: event.target.value });
  };
  cashBackRewordValueChange = (event) => {
    this.setState({ cashBackRewordValue: event.target.value });
  };
  productsChange = (event) => {
    this.setState({ products: event.target.value });
  };
  cashBackFromValueChange = (event) => {
    this.setState({ cashBackFromValue: event.target.value });
  };
  rewordBudgetValueChange = (event) => {
    this.setState({ rewordBudgetValue: event.target.value });
  };
  cashBackToValueChange = (event) => {
    this.setState({ cashBackToValue: event.target.value });
  };
  cashBackPromotionStart = async (date) => {
    this.setState({
      cash_back_promotion_date_from: date,
      enableBtn: true,
    });
  };
  cashBackPromotionEnd = async (date) => {
    this.setState({
      cash_back_promotion_date_to: date,
      enableBtn: true,
    });
  };
  handleTierChange = (selectedTierOption) => {
    this.setState({ tierSelected: selectedTierOption });
  };
  handleProductsChange = (selectedProductsOption) => {
    this.setState({ productsSelected: selectedProductsOption });
  };
  topupMethodChange = (selectedTmethodOption) => {
    this.setState({ topup_method_Selected: selectedTmethodOption });
  };

  tierNameChange = (event) => {
    this.setState({ tierName: event.target.value });
  };
  onChangeRpsCheck = (event) => {
    this.setState({
      isRpsChecked: !this.state.isRpsChecked,
    });
  };
  onChangeAppCheck = (event) => {
    this.setState({
      isAppChecked: !this.state.isAppChecked,
    });
  };

  onChangeTopupMethodCheck = (event) => {
    const { method_top } = this.state;
    this.setState({
      method_top: method_top,
      enableBtn: true,
      isTMChecked: !this.state.isTMChecked,
      isAppChecked: !this.state.isAppChecked,
      isRpsChecked: !this.state.isRpsChecked,
    });
    // if (event.target.checked) {
    //   this.setState({
    //     event_lock: false,
    //     isTMChecked: true,
    //   });
    //   this.clearValue();
    // } else {
    //   this.setState({
    //     event_lock: true,
    //     isTMChecked: false,
    //   });
    //   this.clearTopMethodValue();
    // }
  };

  onChangeTopupMethodCheck = (event) => {
    const { method_top } = this.state;
    this.setState({
      method_top: method_top,
      enableBtn: true,
      isTMChecked: !this.state.isTMChecked,
    });
    // if (event.target.checked) {
    //   this.setState({
    //     event_lock: false,
    //     isTMChecked: true,
    //   });
    //   this.clearValue();
    // } else {
    //   this.setState({
    //     event_lock: true,
    //     isTMChecked: false,
    //   });
    //   this.clearTopMethodValue();
    // }

    // if (
    //   event.target.checked ||
    //   this.state.isAppChecked ||
    //   this.state.isRpsChecked
    // ) {
    //   this.setState({
    //     isAppChecked: false,
    //     isRpsChecked: false,
    //   });
    // } else {
    //   this.setState({
    //     isAppChecked: true,
    //     isRpsChecked: true,
    //   });
    // }
  };

  saveCashBack = async (event) => {
    let error = false;

    if (!this.state.cash_back_promotion_date_from) {
      error = true;
      this.setState({
        error: true,
        message: "Start Date is required",
      });
      return;
    }
    if (!this.state.cash_back_promotion_date_to) {
      error = true;
      this.setState({
        error: true,
        message: "End Date is required",
      });
      return;
    }
    if (!this.state.tierName) {
      error = true;
      this.setState({
        error: true,
        message: "Name value is required",
      });
      return;
    }
    if (
      !this.state.isTMChecked &&
      Array.isArray(this.state.tierSelected) &&
      this.state.tierSelected.length == 0
    ) {
      error = true;
      this.setState({
        error: true,
        message: "Tier is required",
      });
      return;
    }

    if (
      !this.state.isTMChecked &&
      Array.isArray(this.state.productsSelected) &&
      this.state.productsSelected.length == 0
    ) {
      error = true;
      this.setState({
        error: true,
        message: "Products is required",
      });
      return;
    }
    if (
      this.state.isTMChecked &&
      Array.isArray(this.state.topup_method_Selected) &&
      this.state.topup_method_Selected.length == 0
    ) {
      error = true;
      this.setState({
        error: true,
        message: "Topup Method is required",
      });
      return;
    }
    if (!this.state.cashBackFromValue) {
      error = true;
      this.setState({
        error: true,
        message: "Topup value from is required",
      });
      return;
    }
    if (!this.state.cashBackToValue) {
      error = true;
      this.setState({
        error: true,
        message: "Topup value to is required",
      });
      return;
    }
    if (!this.state.cashBackRewordValue) {
      error = true;
      this.setState({
        error: true,
        message: "Reword Value is required",
      });
      return;
    }
    if (!this.state.rewordBudgetValue) {
      error = true;
      this.setState({
        error: true,
        message: "Reword Budget is required",
      });
      return;
    }


    if (this.state.rewardBudgetValue <= 0) {
      error = true;
      this.setState({
        error: true,
        message: "Reward Budget must be greater than 0",
      });
      return;
    }


    var productsPost = [];
    this.state.productsSelected.map((store) => {
      productsPost.push(store.value);
    });

    var tierPost = [];
    this.state.tierSelected.map((store) => {
      tierPost.push(store.value);
    });

    var topMethod = [];
    this.state.topup_method_Selected &&
      this.state.topup_method_Selected.map((topMethods) => {
        topMethod.push(topMethods.value);
      });

    const formData = new FormData();

    formData.append(
      "from",
      moment
        .utc(this.state.cash_back_promotion_date_from)
        // .startOf("day")
        .tz("Asia/Muscat")
        .valueOf()
    );
    formData.append(
      "to",
      moment
        .utc(this.state.cash_back_promotion_date_to)
        // .add(1, "days")
        // .endOf("day")
        .tz("Asia/Muscat")
        .valueOf()
    );
    formData.append("reward_type", this.state.tier_target);
    formData.append("reward_value", this.state.cashBackRewordValue);
    formData.append("products", JSON.stringify(productsPost));
    formData.append("name", this.state.tierName);
    formData.append("tiers", JSON.stringify(tierPost));
    formData.append("budget", this.state.rewordBudgetValue);
    formData.append("topup_method", JSON.stringify(topMethod));
    formData.append(
      "transaction_value_range_from",
      this.state.cashBackFromValue
    );
    formData.append("transaction_value_range_to", this.state.cashBackToValue);

    var transaction_type_data = [];
    if (this.state.isRpsChecked === true) {
      transaction_type_data.push("rps");
    }
    if (this.state.isAppChecked === true) {
      transaction_type_data.push("app");
    }
    if (this.state.isTMChecked === true) {
      transaction_type_data.push("topup");
    }
    formData.append("transaction_type", JSON.stringify(transaction_type_data));

    let api_response = {};
    let options = { url: edit_cash_back_form_url, form_data: formData };
    options.url = edit_cash_back_form_url + "/" + this.props.match.params.id;
    api_response = await ApiRequest.put(options);

    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        success: true,
        loading: false,
        success_message: api_response.message,
      });
      setTimeout(
        function () {
          this.setState({ success: false });
          window.location = "/cash_back_promotion";
        }.bind(this),
        10000
      );
    }
  };

  backUser = () => {
    window.location = "/cash_back_promotion";
  };

  render() {
    let {
      error,
      message,
      success,
      success_message,
      stores,
      productsSelected,
      tierSelected,
      t_method,
      topup_method_Selected,
      //topupChecked,
      // selectedTierOption,
      // selectedProductsOption,
    } = this.state;

    var products = [
      { value: "MOGAS 91", label: "MOGAS 91" },
      { value: "MOGAS 95", label: "MOGAS 95" },
      { value: "Diesel", label: "Diesel" },
      { value: "MOGAS 98", label: "MOGAS 98" },
    ];

    return (
      <Content title="Cash Back Promotion" browserTitle="Cash Back Promotion">
        <LoadingAjax />
        <div className="row">
          {error && (
            <div
              className="alert alert-danger alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {message}
            </div>
          )}
          {success && (
            <div
              className="alert alert-success alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {success_message}
            </div>
          )}
          <div className="col-md-12">
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">Cash Back Promotion Form </h3>
              </div>

              <div className="box-body">
                <div className="col-md-4">
                  <div className="form-group event_lock">
                    <label htmlFor="loyaltyGameId">Promotion Type</label>
                    <select
                      className="form-control"
                      id="loyaltyGameId"
                      value={this.state.promotionType}
                      onChange={this.promotionTypeChange}
                    >
                      <option> Cash Back Promotion </option>
                    </select>
                  </div>
                </div>
                <div className="form-group rangeFilter col-sm-4">
                  <label>Promotion Date</label>
                  <DatePicker
                    showTimeSelect
                    dateFormat="Pp"
                    //dateFormat="MM/dd/yyyy"
                    selected={this.state.cash_back_promotion_date_from}
                    selectsStart
                    cash_back_promotion_date_from={
                      this.state.cash_back_promotion_date_from
                    }
                    cash_back_promotion_date_to={
                      this.state.cash_back_promotion_date_to
                    }
                    onChange={this.cashBackPromotionStart}
                    minDate={moment().toDate()}
                    placeholderText="From"
                  />
                  <DatePicker
                    showTimeSelect
                    dateFormat="Pp"
                    // dateFormat="MM/dd/yyyy"
                    selected={this.state.cash_back_promotion_date_to}
                    selectsEnd
                    cash_back_promotion_date_from={
                      this.state.cash_back_promotion_date_from
                    }
                    cash_back_promotion_date_to={
                      this.state.cash_back_promotion_date_to
                    }
                    onChange={this.cashBackPromotionEnd}
                    minDate={this.state.cash_back_promotion_date_from}
                    placeholderText="To"
                  />
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="tierName">Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Name"
                      disabled ={true} 
                      value={this.state.tierName}
                      onChange={this.tierNameChange}
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="tierTarget">Topup value </label>
                    <div className="form-group-custom">
                      <div className="form-control form-group-custom mr-5">
                        <input
                          type="number"
                          min="0"
                          disabled ={true}
                          className="bdr0 cusInput "
                          placeholder="From"
                          
                          value={this.state.cashBackFromValue}
                          onChange={this.cashBackFromValueChange}
                        />
                        <span style={{ color: "#ccc" }}>OMR</span>
                      </div>
                      <div className="form-control form-group-custom ">
                        <input
                          type="number"
                          min="0"
                          disabled ={true}
                          className="bdr0 cusInput"
                          placeholder="To"
                          value={this.state.cashBackToValue}
                          onChange={this.cashBackToValueChange}
                        />
                        <span style={{ color: "#ccc" }}>OMR</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="reword">Reward Value </label>
                    <div className="form-group-custom ">
                      <select
                        style={{ width: "50%" }}
                        className="form-control mr-5"
                        disabled ={true}
                        value={this.state.tier_target}
                        onChange={this.tierTargetChange}
                      >
                        <option value="percentage">Percentage</option>
                        <option value="fixed">Fixed</option>
                      </select>
                      <input
                        type="number"
                        min="0"
                        className="form-control"
                        placeholder="00"
                        disabled ={true}
                        value={this.state.cashBackRewordValue}
                        onChange={this.cashBackRewordValueChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="rewordBudget">Reward Budget</label>
                    <input
                      type="number"
                      min="0"
                      className="form-control"
                      placeholder="Reword budget"
                      value={this.state.rewordBudgetValue}
                      onChange={this.rewordBudgetValueChange}
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="event_lock">
                    <label>
                      <input
                        className="ml-5"
                        onChange={this.onChangeTopupMethodCheck}
                        type="checkbox"
                        // checked={method_top}
                        //checked={topupChecked}
                        checked={this.state.isTMChecked}
                      />
                      {"Topup"}
                    </label>
                  </div>
                </div>
                <div className="col-md-4">
                  {/*<div className={!this.state.event_lock ? "event_lock" : ""}>*/}
                  <div className="event_lock">
                    <label>
                      <input
                        className="ml-5"
                        onChange={this.onChangeRpsCheck}
                        type="checkbox"
                        id="physicalId"
                        // checked={physical}
                        checked={this.state.isRpsChecked}
                      />
                      {"Rps"}
                    </label>
                  </div>
                </div>

                <div className="col-md-4">
                  {/*<div className={!this.state.event_lock ? "event_lock" : ""}>*/}
                  <div className="event_lock">
                    <label>
                      <input
                        className="ml-5"
                        onChange={this.onChangeAppCheck}
                        type="checkbox"
                        id="surveySponsored"
                        // checked={virtual}
                        checked={this.state.isAppChecked}
                      />
                      {"App"}
                    </label>
                  </div>
                </div>

                <div className="col-md-4">
                  <div
                    className={"form-group event_lock"}
                  >
                    <label htmlFor="tierTarget">Topup Method</label>
                    <Select
                    ref={(ref) => {
                        this.selectRefTopMethod = ref;
                      }}
                      onChange={this.topupMethodChange}
                      options={t_method}
                      value={topup_method_Selected}
                      isMulti
                      isClearable={true}
                      isSearchable
                      
                    />
                  </div>
                </div>
                <div className="col-md-4">
                <div
                    className={"form-group event_lock"}
                  >
                    <label htmlFor="tierTarget">Tier Target</label>
                    <Select
                      ref={(ref) => {
                        this.selectRefTier = ref;
                      }}
                      onChange={this.handleTierChange}
                      options={stores}
                      value={tierSelected}
                      isMulti
                      isClearable={true}
                      isSearchable
                    />
                  </div>
                </div>
                <div className="col-md-4">
                <div
                    className={"form-group event_lock"}
                  >
                    <label htmlFor="products">Products</label>
                    <Select
                      ref={(ref) => {
                        this.selectRefProduct = ref;
                      }}
                      value={productsSelected}
                      onChange={this.handleProductsChange}
                      options={products}
                      isMulti
                      isSearchable
                      isClearable={true}
                    />
                  </div>
                </div>
              </div>
              <div className="box-footer clearfix no-border">
                <button
                  onClick={this.saveCashBack}
                  className="btn btn-primary pull-right "
                >
                  Submit
                </button>
                <button
                  onClick={this.backUser}
                  className="btn btn-default pull-right mr-10"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Content>
    );
  }
}
