import React from "react";
import { Content, Row, Col } from "adminlte-2-react";
import { Inputs } from "adminlte-2-react";
import ApiRequest from "./../../ApiRequest";
import API from "./../../constants";
import LoadingSpinner from "./../../components/LoadingSpinner";
import LoadingAjax from "../../components/Loading";
import Pagination from "react-js-pagination";
import DatePicker from "react-datepicker";
import { confirmAlert } from "react-confirm-alert";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
require("bootstrap/less/bootstrap.less");

const store_url = API.apiEndpoint + API.store;
const completeTransaction_url = API.apiEndpoint + API.completeThawaniTransaction;
const verifyTransaction_url = API.apiEndpoint + API.verifyThawaniTransaction;
const thawaniTransaction_url = API.apiEndpoint + API.thawaniTransaction;
const refundTransaction_url = API.apiEndpoint + API.refundThawaniTransaction;

const getStatCards = (stats) => {

  const { total_transactions, expired, completed, 
    total_amount, topup_error, failed, pending, 
    thawani_refunded,
    thawani_refunded_rps_refund_error,
    thawani_refunded_rps_refunded,
    refunded_total_amount} = stats

  const total = total_transactions - expired

  return[
    { title: "Total attempts", value: total_transactions },
    { title: "Total transactions", value: total },
    { title: `PG Pending (${(pending/total * 100).toFixed(2)}%)`, value: pending },
    { title: `Topup Completed (${(completed/total * 100).toFixed(2)}%)`, value: completed },
    { title: "Total Amount (OMR)", value: total_amount },
    { title: `RPS top up error (${(topup_error/total * 100).toFixed(2)}%)`, value: topup_error },
    { title: `PG Canceled (${(failed/total * 100).toFixed(2)}%)`, value: failed },
    { title: `PG Expired`, value: expired },
    { title: "Total Completed Refunds (OMR)", value: refunded_total_amount},
    { title: `Thawani & RPS Refunded (${(thawani_refunded_rps_refunded/total * 100).toFixed(2)}%)`, value: thawani_refunded_rps_refunded },
    { title: `Thawani Refunded & RPS Pending (${(thawani_refunded/total * 100).toFixed(2)}%)`, value: thawani_refunded },
    { title: `Thawani Refunded & RPS Failed (${(thawani_refunded_rps_refund_error/total * 100).toFixed(2)}%)`, value: thawani_refunded_rps_refund_error },
  ]
}


const StatusList = {
  pending: "PG pending",
  failed: "PG canceled",
  completed: "Topup completed",
  success_received_topup_error: "RPS Topup error",
  expired: "PG expired",
  intent_created: "Intent Created",
  thawani_refunded: "Thawani Refunded & RPS Pending", 
  thawani_refunded_rps_refund_error: "Thawani Refunded & RPS Failed", 
  thawani_refunded_rps_refunded: "Thawani and RPS refunded"
}

export default class EPaymentTransaction extends React.Component {
  state = {
    data: [],
    submitUrl: "",
    date_from: new Date(),
    date_to: new Date(),
    amount_from: "",
    amount_to: "",
    serial_number_from: "",
    serial_number_to: "",
    oomco_id: "",
    payment_id: "",
    refId: "",
    epaymentTransaction: [],
    stats: [],
    activePage: 1,
    error: false,
    success: false,
    message: "",
    success_message: "",
    loading: false,
    limit: 20,
    callApi: false,
    enableBtn: false,
    search_clear: true,
    status: "",
    pagination: {},
    refundTicketNumber: null, 
    refundReason: null
  };

  async fetchData() {
    let options = { url: store_url };
    let api_response = await ApiRequest.get(options);

    let optionsEpaymentTrans = { url: thawaniTransaction_url };
    let report_response = await ApiRequest.get(optionsEpaymentTrans);

    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        // epaymentTransaction: report_response.data.results,
        // pagination: report_response.data.pagination,
        loading: false,
        error: false,
        message: api_response.message,
      });

      if (this.callApi) {
        await ApiRequest.get(options);
        const { results, pagination, ...stats } = report_response.data

        this.setState({
          epaymentTransaction: report_response.data.results,
          stats,
          pagination: report_response.data.pagination,
        });
      }
    }
  }

  getReportData = async (pageNumber = 1) => {
    this.setState({ loading: true });
    let url = thawaniTransaction_url + "?page=" + pageNumber;

    if (this.state.date_from) {
      url = url + "&date_from=" + this.state.date_from;
    }
    if (this.state.date_to) {
      url = url + "&date_to=" + this.state.date_to;
    }
    if (this.state.amount_from) {
      url = url + "&amount_from=" + this.state.amount_from;
    }
    if (this.state.amount_to) {
      url = url + "&amount_to=" + this.state.amount_to;
    }
    if (this.state.serial_number_from) {
      url = url + "&serial_number_from=" + this.state.serial_number_from;
    }
    if (this.state.serial_number_to) {
      url = url + "&serial_number_to=" + this.state.serial_number_to;
    }
    if (this.state.oomco_id) {
      url = url + "&oomco_id=" + this.state.oomco_id;
    }
    if (this.state.payment_id) {
      url = url + "&payment_id=" + this.state.payment_id;
    }
    if (this.state.refId) {
      url = url + "&refId=" + this.state.refId;
    }
    
    if (this.state.status) {
      url = url + "&status=" + this.state.status;
    }

    let options = { url: url };
    let response = await ApiRequest.get(options);
    if (response.error) {
      this.setState({
        loading: false,
        error: true,
        message: response.message,
      });
    } else {
      const { results, pagination, ...stats } = response.data

      this.setState({
        stats,
        epaymentTransaction: response.data.results,
        pagination: response.data.pagination,
        loading: false,
        error: false,
        callApi: true,
      });
    }
  };

  async componentDidMount() {
    this.fetchData();
    this.getReportData();
  }

  downloadData = async (pageNumber = 1) => {
    let url = thawaniTransaction_url + "?csv=" + 1;
    if (this.state.date_from) {
      url = url + "&date_from=" + this.state.date_from;
    }
    if (this.state.date_to) {
      url = url + "&date_to=" + this.state.date_to;
    }
    if (this.state.amount_from) {
      url = url + "&amount_from=" + this.state.amount_from;
    }
    if (this.state.amount_to) {
      url = url + "&amount_to=" + this.state.amount_to;
    }
    if (this.state.serial_number_from) {
      url = url + "&serial_number_from=" + this.state.serial_number_from;
    }
    if (this.state.serial_number_to) {
      url = url + "&serial_number_to=" + this.state.serial_number_to;
    }
    if (this.state.oomco_id) {
      url = url + "&oomco_id=" + this.state.oomco_id;
    }
    if (this.state.payment_id) {
      url = url + "&payment_id=" + this.state.payment_id;
    }
    if (this.state.refId) {
      url = url + "&refId=" + this.state.refId;
    }
    
    if (this.state.status) {
      url = url + "&status=" + this.state.status;
    }

    let options = {
      url: url,
      file_name:
        "Thawani transactions - " + ApiRequest.date_format_utc(Date.now()) + ".csv",
    };
    await ApiRequest.download(options);
  };
  handleSubmit = async (event) => {
    await this.getReportData();
  };
  handlePageChange = async (pageNumber) => {
    await this.getReportData(pageNumber);
  };
  byDateStart = (date) => {
    this.setState({
      date_from: date,
      enableBtn: true,
      pagination: {},
      limit: 20,
    });
  };
  byDateEnd = (date) => {
    this.setState({
      date_to: date,
      enableBtn: true,
      pagination: {},
      limit: 20,
    });
  };
  handleChangeAmountFrom = (event) => {
    this.setState({
      amount_from: event.target.value,
      enableBtn: true,
      pagination: {},
      limit: 20,
    });
  };
  handleChangeAmountTo = (event) => {
    this.setState({
      amount_to: event.target.value,
      enableBtn: true,
      pagination: {},
      limit: 20,
    });
  };
  handleChangeSerialNumberFrom = (event) => {
    this.setState({
      serial_number_from: event.target.value,
      enableBtn: true,
      pagination: {},
      limit: 20,
    });
  };
  handleChangeSerialNumberTo = (event) => {
    this.setState({
      serial_number_to: event.target.value,
      enableBtn: true,
      pagination: {},
      limit: 20,
    });
  };
  handleChangeOomcoID = (event) => {
    this.setState({
      enableBtn: true,
      pagination: {},
      limit: 20,
      oomco_id: event.target.value,
    });
  };
  handleChangePaymentID = (event) => {
    this.setState({
      enableBtn: true,
      pagination: {},
      //limit: 10,
      payment_id: event.target.value,
    });
  };
  handleChangeRefID = (event) => {
    this.setState({
      enableBtn: true,
      pagination: {},
      //limit: 10,
      refId: event.target.value,
    });
  };
  
  handleChangeStatus = (event) => {
    this.setState({
      status: event.target.value,
      enableBtn: true,
      pagination: {},
      limit: 20,
    });
  };
  handleSearchClear = async () => {
    this.setState({
      search_clear: true,
      date_from: new Date(),
      date_to: new Date(),
      amount_from: "",
      amount_to: "",
      serial_number_from: "",
      serial_number_to: "",
      oomco_id: "",
      payment_id: "",
      refId: "",
      status: "",
    });
  };
  rescue_value(val) {
    return val === undefined || val === null || val.length <= 0 ? "-" : val;
  }
  completeTransaction = async (transactionId) => {
    confirmAlert({
      title: "Complete Transaction ",
      message: "Are you sure you want to complete this transaction?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.confirmCompleteTransaction(transactionId),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };
  confirmCompleteTransaction = async (transactionId) => {
    const formData = new FormData();
    formData.append("paymentId", transactionId);
    this.setState({ loading: true });

    let headers = {
      "X-Access-Token": localStorage.getItem("user_token"),
    };
    await axios
      .post(completeTransaction_url, formData, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            success: true,
            loading: false,
            success_message: response.data.message,
          });
          setTimeout(
            function () {
              this.setState({ success: false });
              let currentPage = window.location.href;
              window.location = currentPage;
            }.bind(this),
            100
          );
        }
      })
      .catch((error) => {
        this.setState({
          error: true,
          loading: false,
          message: error.response.data
            ? error.response.data.message
            : error.response.message,
        });
      });
  };

  verifyTransaction = async (transactionId) => {
    const formData = new FormData();
    formData.append("paymentId", transactionId);
    this.setState({ loading: true });

    let headers = {
      "X-Access-Token": localStorage.getItem("user_token"),
    };
    await axios
      .post(verifyTransaction_url, formData, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            success: true,
            loading: false,
            success_message: response.data.message,
          });
          setTimeout(
            function () {
              this.setState({ success: false });
            }.bind(this),
            5000
          );
        }
      })
      .catch((error) => {
        console.log("error here ======", error)
        this.setState({
          error: true,
          loading: false,
          message: error.response && error.response.data
            ? error.response.data.message 
            : error.message
        });
      });
  }

  refundTransaction = async (transactionId, refundTicketNumber, refundReason, onClose) => {
    const formData = new FormData();
    formData.append("transaction_id", transactionId);
    formData.append("reason", `Refund Ticket: ${refundTicketNumber} | Refund Reason: ${refundReason}`);
    this.setState({ loading: true });

    let headers = {
      "X-Access-Token": localStorage.getItem("user_token"),
    };
    await axios
      .post(refundTransaction_url, formData, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            success: true,
            loading: false,
            success_message: response.data.message,
          });
          onClose();
          setTimeout(
            function () {
              this.setState({ success: false });
              this.fetchData();
            }.bind(this),
            3000
          );
        }
      })
      .catch((error) => {
        console.log("error here ======", error)
        onClose()
        this.setState({
          error: true,
          loading: false,
          message: error.response && error.response.data
            ? error.response.data.message 
            : error.message
        });
        setTimeout(() => {
          this.fetchData();
        }, 5000);
      });
  }

  confirmRefundTransaction = async (val, event) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body">
            <h1>Refund Transaction</h1>Are you sure you want to refund this
            transaction?
            <div style={{marginTop: "1em"}}>Ticket Number:</div>
            <input
              className="form-control"
              style={{textAlign: "start"}}
              onChange={(e) => {
                this.setState({ refundTicketNumber: e.target.value })
              }}
              required
            />
            <div style={{ marginTop: "1em"}}>Reason:</div>
            <textarea
              className="form-control"
              style={{textAlign: "start"}}
              onChange={(e) => {
                this.setState({ refundReason: e.target.value })
              }}
              required
            ></textarea>
            <div className="react-confirm-alert-button-group">
              <button 
                label="Yes" 
                onClick={() => this.refundTransaction(val, this.state.refundTicketNumber, this.state.refundReason, onClose)}
                >Yes</button>
              <button 
                label="No" 
                onClick={() => onClose()}>No</button>
            </div>
          </div>
        );
      },
    });
  };

  renderReasonPopup = (remarks) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        let reasons = remarks.split(" | ");
        return (
          <div className="react-confirm-alert-body">
            <h2>Transaction Remarks</h2>
            {reasons.map((reason) => {
              const title = reason.split(': ')[0];
              const description = reason.split(': ')[1];
              return (
                <div style={{ marginTop: "2em", display: "flex", justifyContent: "start", alignItems: "center", gap: "1em"}}>
                  <span style={{fontWeight: "bold"}}>{title} :</span>
                  <span>{description}</span>
                </div>
              )
            })}
            <div className="react-confirm-alert-button-group">
              <button label="Close" onClick={() => onClose()}>
                Close
              </button>
            </div>
          </div>
        );
      },
    });
  };

  renderReason = (remarks) => {
    return (
      <button
        type="button"
        className="btn btn-primary btn-xs"
        onClick={() => this.renderReasonPopup(remarks)}
      >
        Show Reason
      </button>
    );
  };

  renderCompletedRemarks = (completedByAdmin, completedAt) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        const adminName = completedByAdmin?.name ?? '';
        return (
          <div className="react-confirm-alert-body">
            <h2>Transaction Remarks</h2>
              <div style={{ marginTop: "2em"}}>
                <span>Transaction completed to RPS by: </span>
                <div style={{fontWeight: "bold"}}>
                  <a href={"/admin_user_view/" + completedByAdmin.id} target="_blank" rel="noreferrer">
                    {adminName}
                  </a>
                </div>
                <span style={{margin: "0 1em"}}>At: </span>
                <span style={{fontWeight: "bold"}}>{`${ApiRequest.date_format(completedAt)} - ${ApiRequest.time_format(completedAt)}`}</span>
              </div>
            <div className="react-confirm-alert-button-group">
              <button label="Close" onClick={() => onClose()}>
                Close
              </button>
            </div>
          </div>
        );
      },
    });
  }; 

  noResultText() {
    return "N/A";
  }

  render() {
    const { Select2 } = Inputs;
    const {
      epaymentTransaction,
      stats,
      pagination,
      loading,
      error,
      callApi,
      enableBtn,
      message,
      limit,
      success,
      success_message
    } = this.state;
    return (
      <Content
        title="Thawani transactions"
        browserTitle="Thawani transactions"
      >
        <LoadingAjax />
        <Row>
          {error && (
            <div
              class="alert alert-danger alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {message}
            </div>
          )}
          {success && (
              <div
                class="alert alert-success alert-dismissible"
                style={{ marginRight: "15px", marginLeft: "15px" }}
              >
                <button
                  type="button"
                  class="close"
                  data-dismiss="alert"
                  aria-hidden="true"
                >
                  ×
                </button>
                {success_message}
              </div>
            )}
          <Col xs={12}>
            <div className="box box-primary">
              <div className="box-header with-border box-table-cus">
                <h3 className="box-title">Report</h3>
                <div class="box-tools">
                  <button
                    className="btn btn-sm btn-primary "
                    onClick={this.downloadData}
                  >
                    Export
                  </button>
                </div>
              </div>
              <div
                className="row clearfix"
                style={{ marginTop: "5px", padding: "10px" }}
              >
                <div className="col-xs-4">
                  <div className="form-group rangeFilter">
                    <label>By Date</label>
                    <DatePicker
                      dateFormat="MM/dd/yyyy"
                      selected={this.state.date_from}
                      selectsStart
                      date_from={this.state.date_from}
                      date_to={this.state.date_to}
                      onChange={this.byDateStart}
                      placeholderText="From"
                    />
                    <DatePicker
                      dateFormat="MM/dd/yyyy"
                      selected={this.state.date_to}
                      selectsEnd
                      date_from={this.state.date_from}
                      date_to={this.state.date_to}
                      onChange={this.byDateEnd}
                      minDate={this.state.date_from}
                      placeholderText="To"
                    />
                  </div>
                </div>
                <div className="col-xs-4">
                  <div className="form-group rangeFilter">
                    <label
                      style={{
                        display: "block",
                      }}
                    >
                      Amount{" "}
                    </label>
                    <input
                      type="number"
                      min={0}
                      value={this.state.amount_from}
                      onChange={this.handleChangeAmountFrom}
                      className="form-control"
                      placeholder="From"
                      style={{
                        display: "inline-block",
                        width: "49%",
                        margin: "0 .7% 0 0",
                      }}
                    />
                    <input
                      type="number"
                      min={0}
                      value={this.state.amount_to}
                      onChange={this.handleChangeAmountTo}
                      className="form-control"
                      placeholder="To"
                      style={{
                        display: "inline-block",
                        width: "49%",
                        margin: "0 .7% 0 0",
                      }}
                    />
                  </div>
                </div>
                <div className="col-xs-4">
                  <div className="form-group rangeFilter">
                    <label
                      style={{
                        display: "block",
                      }}
                    >
                      Serial Number{" "}
                    </label>
                    <input
                      type="number"
                      min={0}
                      value={this.state.serial_number_from}
                      onChange={this.handleChangeSerialNumberFrom}
                      className="form-control"
                      placeholder="Serial Number From"
                      style={{
                        display: "inline-block",
                        width: "49%",
                        margin: "0 .7% 0 0",
                      }}
                    />
                    <input
                      type="number"
                      min={0}
                      value={this.state.serial_number_to}
                      onChange={this.handleChangeSerialNumberTo}
                      className="form-control"
                      placeholder="Serial Number To"
                      style={{
                        display: "inline-block",
                        width: "49%",
                        margin: "0 .7% 0 0",
                      }}
                    />
                  </div>
                </div>
                <div className="col-xs-4">
                  <div className="form-group">
                    <label>Reference number</label>
                    <input
                      type="text"
                      value={this.state.refId}
                      onChange={this.handleChangeRefID}
                      className="form-control"
                      placeholder="Enter Reference number"
                    />
                  </div>
                </div>
                <div className="col-xs-4">
                  <div className="form-group">
                    <label>Payment ID </label>
                    <input
                      type="text"
                      value={this.state.payment_id}
                      onChange={this.handleChangePaymentID}
                      className="form-control"
                      placeholder="Enter Payment ID"
                    />
                  </div>
                </div>
                <div className="col-xs-4">
                  <div className="form-group">
                    <label>Status </label>
                    <select
                      className="form-control"
                      value={this.state.status}
                      onChange={this.handleChangeStatus}
                    >
                      <option value="">Select</option>
                      <option value="completed">{StatusList["completed"]}</option>
                      <option value="success_received_topup_error">{StatusList["success_received_topup_error"]}</option>
                      <option value="failed">{StatusList["failed"]}</option>
                      <option value="pending">{StatusList["pending"]}</option>
                      <option value="expired">{StatusList["expired"]}</option>
                      <option value="intent_created">{StatusList["intent_created"]}</option>
                      <option value="thawani_refunded_rps_refunded">{StatusList["thawani_refunded_rps_refunded"]}</option>
                      <option value="thawani_refunded">{StatusList["thawani_refunded"]}</option>
                      <option value="thawani_refunded_rps_refund_error">{StatusList["thawani_refunded_rps_refund_error"]}</option>
                    </select>
                  </div>
                </div>
                <div className="col-xs-3"></div>
                <div className="text-right col-xs-12">
                  {/*<button
                    className="btn btn-primary mr-5"
                    onClick={this.handleSearchClear}
                  >
                    Clear
                  </button>*/}
                  <button
                    onClick={this.handleSubmit}
                    className="btn btn-primary"
                  >
                    Submit
                  </button>
                </div>
              </div>
              <div className="box-body">
                <div className="row">
                  {getStatCards(stats).map(c => <div key={c.title} className="col-md-4 col-sm-6">
                    <div className="info-box-custom">
                      <div className="info-box-content-custom">
                        <h4 className="info-box-text-custom">
                          {c.title}
                          </h4>
                        <span className="info-box-number-custom">
                          {loading ? (
                            <LoadingSpinner />
                          ) : (
                            <div>
                              <span className="info-box-number-custom">
                                {loading ? (
                                  <LoadingSpinner />
                                ) : c.value ? (
                                  <div>
                                    <span className="info-box-number-custom">
                                      {c.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    </span>
                                  </div>
                                ) : (
                                  <div>{this.noResultText()}</div>
                                )}
                              </span>
                            </div>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>)}
                </div>
              </div>
              <div className="box-body">
                <div className="table-responsive">
                  {loading ? (
                    <LoadingSpinner />
                  ) : (
                    <div>
                      <table
                        className="table no-margin"
                        id="export-table-inventory"
                      >
                        <thead>
                          <tr>
                            <th>Payment ID</th>
                            <th>Reference number</th>
                            <th>Type</th>
                            <th>Card Number</th>
                            <th>User</th>
                            <th>Amount</th>
                            <th>Date</th>
                            <th>Time</th>
                            <th>Status</th>
                            <th>Actions</th>
                          </tr>
                        </thead>

                        <tbody>
                          {epaymentTransaction.length > 0 &&
                          epaymentTransaction ? (
                            epaymentTransaction.map((data) => {
                              return (
                                <tr key={data.id}>
                                  <td>
                                    {this.rescue_value(
                                      data.id
                                    )}
                                  </td>
                                  <td>
                                    {this.rescue_value(
                                      data.refId
                                    )}
                                  </td>
                                  <td>{this.rescue_value(data.type)}</td>
                                  <td>{this.rescue_value(data.cardNumber)}</td>
                                  <td>
                                    {
                                      <span>
                                        {data.userData?.oomcoId ? (
                                          <a
                                            target="_blank"
                                            href={"/app_view/" + data.userData.id}
                                            rel="noreferrer"
                                          >
                                            {data.userData?.oomcoId
                                              ? data.userData?.oomcoId
                                              : "-"}
                                          </a>
                                        ) : (
                                          <p>
                                            {data.userData?.oomcoId
                                              ? data.userData?.oomcoId
                                              : "-"}
                                          </p>
                                        )}
                                      </span>
                                    }
                                  </td>
                                  <td>
                                    {this.rescue_value(data.amount)}
                                  </td>
                                  <td>
                                    {ApiRequest.date_format(data.createdAt)}
                                  </td>
                                  <td>
                                    {ApiRequest.time_format(data.createdAt)}
                                  </td>
                                  <td>
				                            {this.rescue_value(StatusList[data.status] || data.status)}
                                  </td>
                                  <td>
                                    {data.status === "success_received_topup_error" ? <button
                                      className="btn btn-primary btn-xs"
                                      onClick={this.completeTransaction.bind(null, data.id)}
                                    >
                                      {"Complete"}
                                    </button>
                                    : data.status === "pending" ? <button
                                      className="btn btn-primary btn-xs"
                                      onClick={this.verifyTransaction.bind(null, data.id)}
                                    >
                                      {"Verify"}
                                    </button>
                                    : data.status === "completed" ? (
                                      <div style={{display: "flex", gap: "0.5em", alignItems: "center"}}>
                                        <button
                                          className="btn btn-primary btn-xs"
                                          onClick={this.confirmRefundTransaction.bind(
                                            null,
                                            data.id
                                          )}
                                        >
                                          {"Refund"}
                                        </button>
                                        {
                                          data?.completedByAdmin && (
                                            <button
                                              className="btn btn-primary btn-xs"
                                              onClick={this.renderCompletedRemarks.bind(
                                                null,
                                                data.completedByAdmin,
                                                data.updatedAt
                                              )}
                                            >
                                              {"Remarks"}
                                            </button>
                                          )
                                        }
                                      </div>
                                    )
                                    : data.status === "thawani_refunded_rps_refunded" ?
                                    this.renderReason(data.refIdDetails?.remarks)
                                    : "-" }
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <td colSpan="10" className="noRecord">
                              {callApi
                                ? "No Record Found"
                                : "Please Select Filter"}
                            </td>
                          )}
                        </tbody>
                      </table>
                      {pagination.size > 0 && (
                        <div className="tableFooter">
                          <div>
                            <div class="dataTables_info">
                              Showing {(pagination.currentPage - 1) * limit + 1}{" "}
                              to {pagination.currentPage * limit} of{" "}
                              {pagination.size} entries
                            </div>
                          </div>
                          <div style={{ textAlign: "right" }}>
                            <Pagination
                              prevPageText={"<"}
                              nextPageText={">"}
                              activePage={pagination.currentPage}
                              itemsCountPerPage={pagination.limit}
                              totalItemsCount={pagination.size}
                              pageRangeDisplayed={5}
                              onChange={this.handlePageChange}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Content>
    );
  }
}