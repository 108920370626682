import React, { Component } from "react";
import { Content, Row, Col, DataTable } from "adminlte-2-react";
//import axios from "axios";
import API from "./../../constants";
import ApiRequest from "./../../ApiRequest";
import LoadingSpinner from "./../../components/LoadingSpinner";
import "./AppUser.css";
import Pagination from "react-js-pagination";
import DatePicker from "react-datepicker";
import LoadingAjax from "../../components/Loading";
const appUser_url = API.apiEndpoint + API.appUser;

export default class AppUserListing extends Component {
  state = {
    appUser_list: [],
    loading: true,
    error: false,
    message: "",
    limit: 10,
    pagination: {},
    activePage: 1,
    search_clear: true,
    search_select_value: "",
    search_input_value: "",
    status_select_value: "",
    search_date: "",
  };

  getData = async (
    pageNumber = 1,
    limit = 10,
    filter = false,
    key = "",
    value = ""
  ) => {
    let options = {
      url:
        appUser_url +
        "?storeListingPage=1&page=" +
        pageNumber +
        "&limit=" +
        limit +
        "&filter=" +
        filter +
        "&key=" +
        key +
        "&value=" +
        value,
    };

    this.setState({ loading: true });
    let api_response = await ApiRequest.get(options);
    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        appUser_list: api_response.data.results,
        loading: false,
        error: false,
        pagination: api_response.data.pagination,
        message: api_response.message,
      });
    }
  };

  async componentDidMount() {
    await this.getData();
  }

  handlePageChange = async (pageNumber) => {
    await this.getData(
      pageNumber,
      this.state.limit,
      this.state.filter,
      this.state.search_select_value,
      this.state.search_input_value ||
        this.state.status_input_value ||
        this.state.search_date
    );
  };

  handleLimitChange = async (event) => {
    this.setState({ limit: event.target.value });
    await this.getData(1, event.target.value);
  };

  handleStatusChange = async (event) => {
    this.setState({
      search_clear: false,
      status_input_value: event.target.value,
      filter: true,
    });
    await this.getData(1, this.state.limit, true, "status", event.target.value);
  };

  searchDate = async (date) => {
    this.setState({
      search_clear: false,
      search_date: date,
      filter: true,
    });
    await this.getData(1, this.state.limit, true, "joinDate", date);
  };

  handleSearchValueChange = async (event) => {
    this.setState({
      search_clear: false,
      search_input_value: event.target.value,
      filter: true,
    });
    await this.getData(
      1,
      this.state.limit,
      true,
      this.state.search_select_value,
      event.target.value
    );
  };

  handleSearchChange = async (event) => {
    this.setState(
      {
        search_clear: false,
        search_select_value: event.target.value,
        search_input_value: "",
        status_input_value: "",
        search_date: "",
        filter: true,
      },
      () => {
        this.onSearchChangeRequest();
      }
    );
  };

  async onSearchChangeRequest() {
    const { limit, search_select_value, search_input_value } = this.state;
    await this.getData(1, limit, true, search_select_value, search_input_value);
  }

  handleSearchClear = async () => {
    this.setState({
      search_clear: true,
      search_input_value: "",
      search_select_value: "",
      status_input_value: "",
      search_date: "",
      filter: false,
    });
    await this.getData(1, this.state.limit, false);
  };

  rescue_value(value) {
    return value === undefined ? "-" : value;
  }
  rescue_status(value) {
    return value === 1 ? "Active" : "Inactive";
  }

  render() {
    const {
      loading,
      error,
      message,
      pagination,
      appUser_list,
      limit,
      search_select_value,
      search_input_value,
      status_input_value,
    } = this.state;
    return (
      <Content title="App Users" browserTitle="App Users">
        <LoadingAjax />
        <Row>
          {error && (
            <div
              class="alert alert-danger alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {message}
            </div>
          )}
          <Col xs={12}>
            <div className="box box-primary">
              <div className="box-header with-border box-table-cus">
                <h3 className="box-title">App Users</h3>
              </div>
              <div className="box-body">
                <div>
                  <div className="tableHeader">
                    <div className="showEntries">
                      Show{" "}
                      <select
                        value={limit}
                        className="form-control input-sm mr-5 ml-5"
                        onChange={this.handleLimitChange}
                      >
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>{" "}
                      entries
                    </div>

                    <div className="cusSearch">
                      Search{" "}
                      <select
                        onChange={this.handleSearchChange}
                        value={search_select_value}
                        className="form-control input-sm mr-5 ml-5"
                      >
                        <option value="">Select Search by </option>
                        <option value={"name"}>Name</option>
                        <option value={"oomcoId"}>OomcoId</option>
                        <option value={"mobile_no"}>Phone</option>
                        <option value={"status"}>Status</option>
                        <option value={"joinDate"}>Join Date</option>
                        <option value={"isPotentialBusinessUser"}>is Potential Business User</option>
                      </select>
                      {search_select_value === "status" ? (
                        <select
                          onChange={this.handleStatusChange}
                          value={status_input_value}
                          className="form-control input-sm mr-5 ml-5"
                        >
                          <option value={"1"}>Active</option>
                          <option value={"0"}>InActive</option>
                        </select>
                      ) : search_select_value === "joinDate" ? (
                        <DatePicker
                          className="filterDate"
                          dateFormat="MM/dd/yyyy"
                          selected={this.state.search_date}
                          selectsStart
                          onChange={this.searchDate}
                          placeholderText=""
                        />
                      ) : (
                        <input
                          type="search"
                          class="form-control input-sm mr-5 ml-5"
                          value={search_input_value}
                          onChange={this.handleSearchValueChange}
                          placeholder=""
                        />
                      )}
                      <button
                        className="btn btn-primary cusSearchBtn"
                        onClick={this.handleSearchClear}
                      >
                        Clear
                      </button>
                    </div>
                  </div>
                  <div className="table-responsive">
                    {loading ? (
                      <LoadingSpinner />
                    ) : (
                      <table className="table no-margin">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Phone </th>
                            <th>OOMCO ID</th>
                            <th>Join Date</th>
                            <th>Status</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {appUser_list.length > 0 ? (
                            appUser_list.map((data) => {
                              return (
                                <tr key={data.id}>
                                  <td>{this.rescue_value(data.id)}</td>
                                  <td>{this.rescue_value(data.name)}</td>
                                  <td>
                                    {this.rescue_value(data.phone_format)}
                                  </td>
                                  <td>{this.rescue_value(data.oomcoId)}</td>
                                  <td>
                                    {ApiRequest.date_format_utc(
                                      this.rescue_value(data.createdAt)
                                    )}
                                  </td>
                                  <td>{this.rescue_status(data.status)}</td>
                                  <td>
                                    {ApiRequest.can_access(
                                      "app_user",
                                      "can_view_single"
                                    ) && (
                                      <a
                                        class="btn btn-primary btn-xs mr-5"
                                        href={"/app_view/" + data.id}
                                      >
                                        <i class="fa fa-eye" />
                                      </a>
                                    )}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <td colSpan="7" className="noRecord">
                              No Record Found
                            </td>
                          )}
                        </tbody>
                      </table>
                    )}
                  </div>
                  {pagination.size > 0 && (
                    <div className="tableFooter">
                      <div>
                        <div class="dataTables_info">
                          Showing {(pagination.currentPage - 1) * limit + 1} to{" "}
                          {pagination.currentPage * limit} of {pagination.size}{" "}
                          entries
                        </div>
                      </div>
                      <div style={{ textAlign: "right" }}>
                        <Pagination
                          prevPageText={"<"}
                          nextPageText={">"}
                          activePage={pagination.currentPage}
                          itemsCountPerPage={pagination.limit}
                          totalItemsCount={pagination.size}
                          pageRangeDisplayed={5}
                          onChange={this.handlePageChange}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {/*<div className="box-body">
                {loading ? (
                  <LoadingSpinner />
                ) : (
                  <DataTable
                    title="News Integration"
                    selectableRows={false}
                    records={records}
                    columns={columns}
                    highlightOnHover={false}
                    pointerOnHover={false}
                    pagination={false}
                    striped={true}
                    data={this.state.appUser_list}
                    paginationPerPage={3}
                    onClickEvents={{
                      // clickable: (data, rowIdx, rowData) => {
                      //   this.props.handleClick(data, "edit");
                      // }
                      clickable: (data, rowIdx, rowData) => {
                        this.props.viewAppUser(data, "view");
                      }
                    }}
                    id="externally-controlled"
                    options={{}}
                  />
                )}
                  </div>*/}
            </div>
          </Col>
        </Row>
      </Content>
    );
  }
}
